import React from "react";

// mui
import { Stack } from "@mui/material";

// components
import Template from "./Template";
import CustomLink from "./CustomLink";

export interface Suggestion {
  id: string;
  title: string;
  description: string;
  userId: string;
  votes: string[];
}

const Index: React.FC = () => {
  return (
    <Template to="/">
      <Stack spacing={3}>
        <CustomLink to="/suggest-a-feature/add" text="Make a new suggestion" />

        <CustomLink
          to="/suggest-a-feature/vote"
          text="Vote for an existing suggestion"
        />
      </Stack>
    </Template>
  );
};

export default Index;
