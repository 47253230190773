/* eslint-disable indent */
import React, { useState } from "react";
import Lightbox from "react-awesome-lightbox";

// mui
import { Dialog, ImageListItem } from "@mui/material";

interface MessageImageProp {
  images: string[];
  image: string;
  index: number;
}

const MessageImage: React.FC<MessageImageProp> = ({ images, image, index }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ImageListItem
        sx={{
          cursor: "pointer",
          bgcolor: "gray",
        }}
        cols={index === 0 ? 3 : 1}
        rows={1}
      >
        <img
          src={image}
          alt=""
          loading="lazy"
          onClick={handleClickOpen}
          style={{ objectFit: "cover", objectPosition: "top center" }}
        />
      </ImageListItem>

      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 20000 }}>
        <Lightbox
          image={images.length === 1 && image}
          images={images.length > 1 && images}
          onClose={handleClose}
          startIndex={index}
        />
      </Dialog>
    </>
  );
};

export default MessageImage;
