import React, { useState, useEffect } from "react";
import { Message, Comment } from "../../api/points";
import images from "../../shared/images";
import variables from "../../shared/variables";
import { format } from "timeago.js";
import ReactPlayer from "react-player/lazy";
import { LinkItUrl, LinkItTwitter, LinkItEmail } from "react-linkify-it";
import {
  IoHeartOutline,
  IoHeart,
  IoChatbubblesOutline,
  IoClose,
} from "react-icons/io5";
import { getUserId } from "../../shared/getUserId";

// firebase
import { addComment, likeComment } from "../../firebase/points";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// mui
import {
  Dialog,
  Stack,
  Avatar,
  IconButton,
  Typography,
  ImageList,
  Button,
  Divider,
} from "@mui/material";

// components
import MessageImage from "../MessageListItem/MessageImage";
import CommentForm from "./CommentForm";
import CommentItem from "./CommentItem";
import CustomAlert from "../CustomAlert/CustomAlert";
import CommentTabs from "./CommentTabs";

interface CommentModalProps {
  message: Message;
  handleClose: () => void;
  open: boolean;
  handleLike: () => void;
}

const CommentModal: React.FC<CommentModalProps> = ({
  message,
  open,
  handleClose,
  handleLike,
}) => {
  const user = useAppSelector(selectUser);

  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState("");
  const [mostLikedActive, setMostLikedActive] = useState(true);
  const [mostRecentActive, setMostRecentActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortedComments, setSortedComments] = useState<Comment[] | undefined>(
    message.comments
  );

  const blankAvatarImgPath =
    images.find((image) => image.id === "light_mode_avatar")?.path || "";

  const postImages = message.images as string[];

  const uid = getUserId();

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  const sortByLikes = (comments: Comment[]) => {
    const sorted = comments
      .slice()
      .sort((a: Comment, b: Comment) => b.likes - a.likes);

    setSortedComments(sorted);
  };

  const sortByRecent = (comments: Comment[]) => {
    const sorted = comments.slice().sort((a: Comment, b: Comment) => {
      return b.id - a.id;
    });

    setSortedComments(sorted);
  };

  const toggleForm = () => setShowInput(!showInput);

  const submitComment = async () => {
    if (!user) {
      return setErrorMessage("You need to be signed in to comment on posts");
    }

    setLoading(true);

    const newComment: Comment = {
      text: comment,
      likes: 0,
      likesArray: [],
      time: new Date().toISOString(),
      username: user?.username,
      userId: user?.id,
      id: +new Date(),
    };

    try {
      await addComment(newComment, message);

      setComment("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const likeAComment = async (id: number) => {
    if (!user) {
      return setErrorMessage("You need to be signed in to like a comment");
    }

    try {
      await likeComment(id, user.id, message);
    } catch (error) {
      console.log(error);
    }
  };

  const mostLiked = () => {
    setMostLikedActive(true);
    setMostRecentActive(false);
    if (message.comments) {
      sortByLikes(message.comments);
    }
  };

  const mostRecent = () => {
    setMostLikedActive(false);
    setMostRecentActive(true);
    if (message.comments) {
      sortByRecent(message.comments);
    }
  };

  useEffect(() => {
    if (mostLikedActive) {
      return message.comments && sortByLikes(message.comments);
    }

    if (mostRecentActive) {
      return message.comments && sortByRecent(message.comments);
    }

    setSortedComments(message.comments);
  }, [message.comments]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "20px",
            height: "80%",
            position: "relative",
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 5, right: 5 }}
          onClick={handleClose}
        >
          <IoClose size="20px" color="black" />
        </IconButton>

        <Stack
          sx={{
            height: "80%",
            p: "20px",
          }}
          spacing={4}
        >
          <Stack justifyContent="center" alignItems="center" spacing={0.5}>
            <Avatar
              alt="profile image"
              src={blankAvatarImgPath}
              sx={{ width: "3.5rem", height: "3.5rem" }}
            />

            <Typography
              sx={{
                fontFamily: variables.fonts.header,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: "center",
                fontSize: {
                  xs: "12px",
                  sm: "15px",
                },
              }}
            >
              @{message.username}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: variables.colors.filterGray }}
            >
              {format(message.time)}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{
                fontFamily: "monospace",
                fontSize: "0.7rem",
                color: "darkolivegreen",
              }}
            >
              {message.location}
            </Stack>

            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "15px",
                },
              }}
            >
              <LinkItUrl>
                <LinkItTwitter>
                  <LinkItEmail>{message.message}</LinkItEmail>
                </LinkItTwitter>
              </LinkItUrl>
            </Typography>

            {/* post image(s) */}
            {postImages.length > 0 && (
              <ImageList
                variant="quilted"
                sx={{
                  width: "100%",
                  height: 250,
                }}
                cols={3}
                rowHeight={postImages.length > 1 ? 125 : 250}
              >
                {postImages.map((image, idx) => {
                  return (
                    <MessageImage
                      key={idx}
                      images={postImages}
                      image={image}
                      index={idx}
                    />
                  );
                })}
              </ImageList>
            )}

            {message.video && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "100%",
                  height: 250,
                  border: "1px solid black",
                }}
              >
                <ReactPlayer
                  url={message.video}
                  controls
                  width="100%"
                  height="100%"
                  style={{
                    backgroundColor: "black",
                  }}
                />
              </Stack>
            )}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                pt: 1,
              }}
            >
              <Button
                disableElevation
                disableRipple
                variant="text"
                sx={{
                  color: variables.colors.filterGray,
                  textTransform: "capitalize",
                  p: 0,
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                onClick={toggleForm}
              >
                {showInput ? "Commenting..." : "Comment?"}
              </Button>

              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.2} alignItems="center">
                  <Typography variant="caption">
                    {message.comments?.length}
                  </Typography>
                  <IconButton
                    sx={{ padding: 0 }}
                    disableRipple
                    onClick={toggleForm}
                  >
                    <IoChatbubblesOutline size="20px" />
                  </IconButton>
                </Stack>

                <Stack direction="row" spacing={0.2} alignItems="center">
                  <Typography variant="caption">
                    {message.likesArray?.length}
                  </Typography>

                  <IconButton
                    sx={{ padding: 0 }}
                    disableRipple
                    onClick={handleLike}
                  >
                    {message?.likesArray?.includes(uid) ? (
                      <IoHeart size="20px" color="black" />
                    ) : (
                      <IoHeartOutline size="20px" />
                    )}
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <CommentTabs
              mostLiked={mostLiked}
              mostRecent={mostRecent}
              mostLikedActive={mostLikedActive}
              mostRecentActive={mostRecentActive}
            />

            {showInput && (
              <CommentForm
                loading={loading}
                toggleForm={toggleForm}
                comment={submitComment}
                handleChange={(event: React.ChangeEvent) => {
                  const target = event.target as HTMLInputElement;
                  setComment(target.value);
                }}
                value={comment}
              />
            )}

            {sortedComments && sortedComments.length > 0 ? (
              <Stack>
                {sortedComments.map((comm) => {
                  return (
                    <CommentItem
                      key={comm.id}
                      comment={comm}
                      handleLikeComment={likeAComment}
                    />
                  );
                })}
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ py: 5 }}
              >
                <Typography>No Comments</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Dialog>

      <CustomAlert
        onClose={handleCloseErrorAlert}
        severity="error"
        message={errorMessage}
      />
    </>
  );
};

export default CommentModal;
