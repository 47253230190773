export default {
  company: {
    namespace: "AGAMA_AFRICANA",
  },
  sizes: {
    paragraphWidth: 500,
    controlsCardActiveHeight: 50,
    controlsCardInactiveHeight: 14,
    controlsCardInactiveHeightHack: 14, // (adjusted so center button would show ) | so that the map fills the screen
    controlsCardHiddenHeight: 0,
    controlsCardHeightUnit: "%",
    mapWidth: 2000,
    mapWidthUnit: "px",
    phonePadding: 0.7,
    phonePaddingUnit: "rem",
    drawerOpenPercentage: 83,
    drawerClosedPercentage: 0,
    roundButtonDiameter: "2.8rem",
    cornerButtonMargin: "0.5rem",
    panelMarginSides: "0.8rem",
    panelMarginTop: "0.5rem",
  },
  numbers: {
    overModalZIndex: 1400,
    modalZIndex: 1300,
    underModalZIndex: 1200,
    spacing: 8,
    numEmptyPostPageLoadsForQuiz: 3,
  },
  timings: {
    drawerTransition: 0.2,
  },
  fonts: {
    header: "Garamond",
    body: "Helvetica",
    logo: "Quantico, sans-serif",
    modalWebsiteBodySize: "0.87rem",
  },
  colors: {
    links: "#000",
    header: "#000",
    borders: "rgba(0, 0, 0, 0.5)",
    hover: "#d9d9d9",
    filterGray: "#777",
  },
};
