import variables from "../shared/variables";

const getKey = (key: string) => `${variables.company.namespace}.${key}`;

export const set = (key: string, value: string): void =>
  localStorage.setItem(getKey(key), value);

export const get = (key: string): string =>
  localStorage.getItem(getKey(key)) || "";

export default {
  set,
  get,
};
