/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";

// components
import UnstyledButton from "../../shared/UnstyledButton";

interface TabsProps {
  test?: string;
  mostLiked: () => void;
  mostRecent: () => void;
  mostLikedActive: boolean;
  mostRecentActive: boolean;
}

// TODO: just need one "Active"
const CommentTabs: React.FC<TabsProps> = ({
  mostLiked,
  mostRecent,
  mostLikedActive,
  mostRecentActive,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 0;
          width: 100%;
          max-width: 500px;
          padding: 0 1rem 0.7rem 1rem;

          li {
            text-decoration: none;
            list-style-type: none;
          }

          button {
            position: relative;
          }

          button:before {
            content: "";
            background-color: black;
            height: 3px;
            width: 0;
            position: absolute;
            bottom: -0.3rem;
            left: 0;
            transition: all 0.4s;
            border-radius: 5px;
          }

          button.selected:before {
            width: 100%;
          }
        `}
      >
        <li>
          <UnstyledButton
            style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
            onClick={mostLiked}
            className={mostLikedActive ? "selected" : ""}
          >
            &nbsp;Most Liked&nbsp;
          </UnstyledButton>
        </li>

        <li>
          <UnstyledButton
            style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
            onClick={mostRecent}
            className={mostRecentActive ? "selected" : ""}
          >
            &nbsp;Most Recent&nbsp;
          </UnstyledButton>
        </li>
      </ul>
    </div>
  );
};

export default CommentTabs;
