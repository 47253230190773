import React from "react";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// firebase
import { logout } from "../../firebase/auth";

// mui
import { Stack, Typography } from "@material-ui/core";

// custom components
import DrawerMenuLink from "./DrawerMenuLink";
import ProfileAvatar from "./ProfileAvatar";
import { trackEvent } from "../../analytics/SegmentAnalytics";
import { getUserId } from "../../shared/getUserId";

const DrawerMenu: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <Stack spacing={3}>
      {user && (
        <Stack alignItems="center" spacing={2}>
          <ProfileAvatar />

          <Typography
            variant="h6"
            sx={{
              textTransform: "lowercase",
              fontWeight: 700,
            }}
          >
            @{user?.username}
          </Typography>
        </Stack>
      )}

      <Stack spacing={3}>
        {user && (
          <DrawerMenuLink
            to="/user-profile"
            text="User Profile"
            onClick={() => {
              trackEvent("User views their profile", {
                username: user?.username,
                email: user?.email,
              });
            }}
          />
        )}

        {user ? (
          <DrawerMenuLink
            onClick={() => {
              trackEvent("User logged out", {
                username: user?.username,
                email: user?.email,
              });
              logout();
            }}
            to="/"
            text="Logout"
          />
        ) : (
          <DrawerMenuLink
            onClick={() => {
              trackEvent("User wants to login", { uid: getUserId() });
            }}
            to="/login"
            text="Login / Register"
          />
        )}
        <DrawerMenuLink to="/share" text="Share Agama With Friends" />

        <DrawerMenuLink to="/suggest-a-feature" text="Suggest A Feature" />

        {/* <DrawerMenuLink to="/" text="Change Room" /> */}

        <DrawerMenuLink to="/" text="Help" />
      </Stack>
    </Stack>
  );
};

export default DrawerMenu;
