import {
  collection,
  addDoc,
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase-config";

interface Suggestion {
  title: string;
  description: string;
  userId: string;
}

export const addSuggestion = async (suggestion: Suggestion) => {
  const pointsRef = collection(db, "suggestions");

  await addDoc(pointsRef, {
    createdAt: new Date().toISOString(),
    votes: [],
    ...suggestion,
  });
};

export const vote = async (uid: string, voteId: string) => {
  const suggRef = doc(db, "suggestions", voteId);

  await updateDoc(suggRef, {
    votes: arrayUnion(uid),
  });
};

export const unVote = async (uid: string, voteId: string) => {
  const suggRef = doc(db, "suggestions", voteId);

  await updateDoc(suggRef, {
    votes: arrayRemove(uid),
  });
};

export const deleteSuggestion = async (id: string) => {
  await deleteDoc(doc(db, "suggestions", id));
};
