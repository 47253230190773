const randomInteger = (max: number): number => {
  return Math.ceil(Math.random() * max);
};

const randomDate = (direction: 1 | -1): Date => {
  const millisecondsFromNow =
    direction * randomInteger(1000 * 60 * 60 * 24 * 365 * 5);
  return new Date(+new Date() + millisecondsFromNow);
};

export default {
  randomDate,
};
