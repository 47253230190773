import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface User {
  id: string;
  username: string;
  email: string;
  profileImage?: string;
}

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const { setUser } = userSlice.actions;

// state selectors
export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
