import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// firebase
import { resetPassword } from "../../firebase/auth";

// mui
import { Stack, Typography, Link } from "@mui/material";

// components
import CustomButton from "../CustomButton/CustomButton";
import TextInput from "../TextInput/TextInput";
import BackButton from "../Drawer/BackButton";
import CustomAlert from "../CustomAlert/CustomAlert";

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleReset = async () => {
    setLoading(true);
    try {
      await resetPassword(email);

      setSuccessMessage(`Reset email sent to ${email}`);
      setEmail("");
      setLoading(false);
    } catch (error) {
      if (!(error instanceof Error)) {
        return;
      }

      if (error.message.includes("invalid-email")) {
        setErrorMessage("Invalid email address");
      } else {
        setErrorMessage("Something went wrong. Try again");
      }

      setLoading(false);
    }
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  const handleCloseSuccessAlert = () => {
    setSuccessMessage("");
  };

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={5}>
        <Stack spacing={2}>
          <TextInput
            type="email"
            label="E-Mail"
            onChange={(event: React.ChangeEvent) => {
              const target = event.target as HTMLInputElement;
              setEmail(target.value);
            }}
            value={email}
          />
        </Stack>

        <Stack spacing={2} alignItems="center">
          <CustomButton
            loading={loading}
            text="Reset Password"
            onClick={handleReset}
            isDisabled={email === ""}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle2">Already have an account?</Typography>

        <Link component={NavLink} to="/login">
          <Typography variant="body2">Sign In</Typography>
        </Link>
      </Stack>

      <BackButton />

      <CustomAlert
        onClose={handleCloseErrorAlert}
        severity="error"
        message={errorMessage}
      />

      <CustomAlert
        onClose={handleCloseSuccessAlert}
        severity="success"
        message={successMessage}
      />
    </Stack>
  );
};

export default ForgotPassword;
