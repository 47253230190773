// TODO: delete this file, maybe?

const currentUserUid = 1;

type User = {
  uid: number;
  handle: string;
};

export const allUsers = (): User[] => {
  return [
    { uid: 1, handle: "user1" },
    { uid: 2, handle: "user2" },
    { uid: 3, handle: "user3" },
    { uid: 4, handle: "user4" },
    { uid: 5, handle: "user5" },
  ];
};

export const findUserByUid = (uid: number): User | undefined =>
  allUsers()
    .filter((user) => user.uid === uid)
    .pop();

export const currentUser = (): User | undefined => {
  return findUserByUid(currentUserUid);
};
