import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: false,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer: (state, { payload }) => {
      state.open = payload;
    },
  },
});

export const { toggleDrawer } = drawerSlice.actions;

// state selectors
export const selectOpen = (state: RootState) => state.drawer.open;

export default drawerSlice.reducer;
