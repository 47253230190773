import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "./firebase-config";
import { createUser, checkUserNameIsTaken } from "./user";

interface registerDetails {
  username: string;
  email: string;
  password: string;
}

interface loginDetails {
  email: string;
  password: string;
}

export const register = async (details: registerDetails) => {
  const { username, email, password } = details;

  try {
    await checkUserNameIsTaken(username);

    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await createUser(user, { username: username.toLowerCase() });
  } catch (error) {
    let message = "Error registering user with email and password";

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }
};

export const login = async (details: loginDetails) => {
  const { email, password } = details;

  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    let message = "Error signing in user with email and password";

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }
};

export const loginWithGoogle = async () => {
  try {
    const { user } = await signInWithPopup(auth, googleProvider);
    await createUser(user);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const loginWithFacebook = async () => {
  try {
    const { user } = await signInWithPopup(auth, facebookProvider);
    await createUser(user);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    let message;

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("AGAMA_AFRICANA.userId");
  } catch (error) {
    let message = "Error logging out";

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }
};
