import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { db } from "./firebase-config";
import { studentAmbassadors } from "../screens/Dashboard/ambassadorList";
import { User } from "../redux/user/userSlice";

export const allActualUsers = async (): Promise<User[]> => {
  const users: User[] = [];

  const querySnapshot = await getDocs(collection(db, "users"));

  querySnapshot.forEach((document) => {
    const ambassadorIds = studentAmbassadors.map((student) => student.id);

    if (!ambassadorIds.includes(document.id)) {
      users.push({
        id: document.id,
        ...document.data(),
      } as User);
    }
  });

  return users;
};

export const findUser = async (userId: string) => {
  const docRef = doc(db, "users", userId);
  const document = await getDoc(docRef);
  const data = document.data();

  return { ...data, id: document.id };
};

export const allUsers = async (): Promise<User[]> => {
  const users: User[] = [];

  const querySnapshot = await getDocs(collection(db, "users"));

  querySnapshot.forEach((document) => {
    users.push({
      id: document.id,
      ...document.data(),
    } as User);
  });

  return users;
};

export const createUser = async (userObj: any, additionalData?: any) => {
  // create user in firestore database
  const userRef = doc(db, "users", `${userObj.uid}`);

  try {
    const snapShot = await getDoc(userRef);

    if (!snapShot.exists()) {
      const { displayName, email, photoURL } = userObj;

      let username = "";

      if (displayName && displayName.includes(" ")) {
        username = displayName.toLowerCase().split(" ").join("_");
      } else {
        username = displayName;
      }

      await setDoc(userRef, {
        username,
        profileImage: photoURL,
        email,
        ...additionalData,
      });
    }
  } catch (error) {
    let message = "Error creating user";

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }

  return userRef;
};

export const checkUserNameIsTaken = async (username: string) => {
  // Create a reference to the users collection
  const usersRef = collection(db, "users");

  try {
    // Create a query against the collection.
    const q = query(
      usersRef,
      where("username", "==", `${username.toLowerCase()}`),
      limit(1)
    );

    const snapShot = await getDocs(q);

    if (!snapShot.empty) {
      throw new Error("auth/username-taken");
    }
  } catch (error) {
    let message = "Error checking if user name is taken";

    if (error instanceof Error) {
      message = error.message;
    }

    throw new Error(message);
  }
};
