import React from "react";
import "../css/spinner.css";

const Spinner = () => {
  const nineDivs = new Array(9).fill(0).map((_, i) => <div key={i}></div>);
  const spinner = <div className="lds-grid">{nineDivs}</div>;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {spinner}
    </div>
  );
};

export default Spinner;
