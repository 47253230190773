import React, { useState, useEffect } from "react";
import { Message, Point } from "../../api/points";
import variables from "../../shared/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import { trackEvent } from "../../analytics/SegmentAnalytics";

// firebase
import { addNewPoint } from "../../firebase/points";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";
import { selectIsThinking } from "../../redux/points/pointsSlice";

// mui
import { Button as MUIButton, TextField } from "@material-ui/core";

// components
import Spinner from "../../shared/Spinner";
import CustomAlert from "../CustomAlert/CustomAlert";
import QuestionContent from "./QuestionContent";

const AnswerTab: React.FC<{
  visiblePoints: Point[];
  onCreatePost: () => void;
}> = ({ visiblePoints, onCreatePost }) => {
  const user = useAppSelector(selectUser);
  const isThinking = useAppSelector(selectIsThinking);

  const [question, setQuestion] = useState<Message | undefined>();
  const [questionPoint, setQuestionPoint] = useState<Point | undefined>();
  const [ans, setAns] = useState("");
  const [chooseQuestionContents, setChooseQuestionContents] = useState(<></>);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickMsg = (msg: Message, point: Point) => {
    setQuestion(msg);
    setQuestionPoint(point);
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  const onSubmit = async () => {
    if (!user) {
      setErrorMessage("You need to be signed in to answer questions.");
      return;
    }

    trackEvent("User answered question", {
      username: user?.username,
    });

    const qnAndAnswerMsg = `Q: ${question?.message}  A: ${ans}`;

    const msgObj: Message = {
      message: qnAndAnswerMsg,
      time: new Date().toISOString(),
      uid: 0,
      location: question?.location || "",
      likes: 0,
      likesArray: [],
      username: user.username,
      userId: user.id,
    };

    const { x, y } = questionPoint || { x: -1, y: -1 };

    const pointObj: Point = {
      id: +new Date(),
      x,
      y,
      type: "Post",
      messages: [msgObj],
    };

    setLoading(true);
    await addNewPoint(pointObj);
    setLoading(false);

    onCreatePost();
  };

  useEffect(() => {
    if (!user) {
      setErrorMessage("You need to be signed in to answer questions");
    }
  }, [user]);

  useEffect(() => {
    if (isThinking) {
      setChooseQuestionContents(<Spinner></Spinner>);
    } else {
      setChooseQuestionContents(
        <QuestionContent points={visiblePoints} onClick={onClickMsg} />
      );
    }
  }, [isThinking, visiblePoints]);

  const renderAskQuestionStage = () => {
    return (
      !question && (
        <div style={{ height: "100%" }}>
          <div style={{ textAlign: "center", padding: "0.5rem 0" }}>
            <p
              style={{
                fontWeight: 700,
                padding: 0,
                margin: 0,
                fontFamily: variables.fonts.body,
              }}
            >
              Pick a question to answer
            </p>
          </div>

          <div style={{ width: "100%", height: "calc(100% - 4rem)" }}>
            {chooseQuestionContents}
          </div>

          <CustomAlert
            onClose={handleCloseErrorAlert}
            severity="error"
            message={errorMessage}
          />
        </div>
      )
    );
  };

  const giveAnswerStage = () => {
    return (
      question && (
        <div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => {
                setQuestion(undefined);
                setQuestionPoint(undefined);
              }}
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: 0,
                margin: 0,
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0.7rem 0.4rem", fontSize: "1.2rem" }}
                icon={faArrowCircleLeft}
              />
            </button>
          </div>
          <motion.div
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            style={{
              marginTop: "0.2rem",
              padding: "0 1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: variables.fonts.body, fontSize: "0.9rem" }}
            >
              <b>Question:</b> {question?.message}
            </span>
          </motion.div>
          <div style={{ marginTop: "3rem", padding: "0 1.3rem" }}>
            <TextField
              id="post-textarea"
              variant="standard"
              placeholder=""
              value={ans}
              onChange={(e) => setAns(e.target.value)}
              multiline
              fullWidth
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1rem 0 1.5rem 0",
            }}
          >
            <MUIButton
              variant="contained"
              disableElevation
              disableRipple
              sx={{
                textTransform: "capitalize",
                bgcolor: "primary.main",
                color: "white",
                padding: "0.4rem 1rem",
                fontWeight: 700,
                borderRadius: "1rem",
                border: "none",
              }}
              onClick={onSubmit}
              size="small"
              disabled={!user || ans === ""}
            >
              {loading ? <ClipLoader color="#fff" size={20} /> : "Tell em!"}
            </MUIButton>
          </div>
        </div>
      )
    );
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {renderAskQuestionStage()}
      {giveAnswerStage()}
    </div>
  );
};

export default AnswerTab;
