import { User } from "../redux/user/userSlice";

interface RoleCollection {
  [key: string]: string[];
}

const userRoles: { [key: string]: RoleCollection } = {
  "agama.africana@gmail.com": { dashboard: ["show", "index"] },
  "emmanuelagbayiza52@gmail.com": { dashboard: ["show"] },
  "jones.annobil77@gmail.com": { dashboard: ["show"] },
  "abenadew17@gmail.com": { dashboard: ["show"] },
  "elikemkojolord@gmail.com": { dashboard: ["show"] },
  "66.mrgrey@gmail.com": { dashboard: ["show"] },
  "hdaniella289@gmail.com": { dashboard: ["show"] },
  "jennykashkayne@gmail.com": { dashboard: ["show"] },
  "egbenusonia@gmail.com": { dashboard: ["show"] },
};

export default function useUserRoles(user: User | null) {
  if (user === null) return null;

  return userRoles[user.email] || null;
}
