import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";

// mui
import { Button } from "@material-ui/core";

interface BackButtonProps {
  to?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ to }) => {
  const navigate = useNavigate();

  return (
    <Button
      disableRipple
      sx={{
        textTransform: "capitalize",
        fontWeight: 600,
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      onClick={() => (to ? navigate(to) : navigate(-1))}
      startIcon={<FaArrowCircleLeft />}
    >
      Back
    </Button>
  );
};

export default BackButton;
