import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Suggestion } from "./index";

// firebase
import { db } from "../../firebase/firebase-config";
import { doc, onSnapshot } from "firebase/firestore";
import { ClipLoader } from "react-spinners";

// mui
import { Stack, Typography } from "@mui/material";

// components
import Template from "./Template";

const SuggestionDetails: React.FC = () => {
  const { suggestionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState<Suggestion | null>(null);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = onSnapshot(
      doc(db, "suggestions", `${suggestionId}`),
      (suggDoc) => {
        setSuggestion({
          id: suggDoc.id,
          userId: suggDoc.data()?.userId,
          title: suggDoc.data()?.title,
          description: suggDoc.data()?.description,
          votes: suggDoc.data()?.votes,
        });
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [suggestionId]);

  return (
    <Template to="/suggest-a-feature/vote">
      <Typography sx={{ mb: 5, textAlign: "center", fontWeight: 200 }}>
        Suggested Feature Details
      </Typography>

      {loading && (
        <Stack justifyContent="center" alignItems="center">
          <ClipLoader />
        </Stack>
      )}

      {suggestion && !loading && (
        <Stack spacing={3}>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {suggestion?.title}
          </Typography>

          <Typography>{suggestion?.description}</Typography>
        </Stack>
      )}
    </Template>
  );
};

export default SuggestionDetails;
