import React, { useState } from "react";
import { getUserId } from "../../shared/getUserId";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

// firebase
import { addSuggestion } from "../../firebase/suggestion";

// mui
import { Stack, TextField, Button, Typography } from "@mui/material";

// components
import Template from "./Template";

const AddNewSuggestion: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await addSuggestion({ title, description, userId: getUserId() });

      setLoading(false);
      navigate("/suggest-a-feature/vote");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setTitle("");
      setDescription("");
    }
  };

  return (
    <Template to="/suggest-a-feature">
      <Typography sx={{ mb: 5, textAlign: "center", fontWeight: 200 }}>
        Make a new suggestion
      </Typography>

      <Stack spacing={5}>
        <TextField
          variant="standard"
          placeholder="Summary or Title"
          onChange={(event: React.ChangeEvent) => {
            const target = event.target as HTMLInputElement;
            setTitle(target.value);
          }}
          value={title}
        />

        <TextField
          variant="outlined"
          placeholder="Suggestion Details"
          multiline
          minRows={6}
          onChange={(event: React.ChangeEvent) => {
            const target = event.target as HTMLInputElement;
            setDescription(target.value);
          }}
          maxRows={8}
          title={description}
        />

        <Stack justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            disableElevation
            disableRipple
            sx={{
              p: 1,
              borderRadius: 5,
              textTransform: "capitalize",
              width: "200px",
            }}
            disabled={title === "" || description === ""}
            onClick={handleSubmit}
          >
            {loading ? (
              <ClipLoader color="white" size="1.5rem" />
            ) : (
              "Submit Your Suggestion"
            )}
          </Button>
        </Stack>
      </Stack>
    </Template>
  );
};

export default AddNewSuggestion;
