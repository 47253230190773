import React from "react";
import { FiXCircle } from "react-icons/fi";

// mui
import { Box, IconButton } from "@mui/material";

interface PreviewProps {
  images: File[];
  removeImage: (name: string) => void;
}
const ImagePreview: React.FC<PreviewProps> = ({ images, removeImage }) => {
  return (
    <>
      {images.length > 0 && (
        <Box
          sx={{
            mb: "10px",
            overflowY: images.length > 2 ? "auto" : "hidden",
            display: "grid",
            gridTemplateColumns: {
              xs: images.length > 1 ? "1fr 1fr" : "1fr",
              sm: images.length > 3 ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr",
            },
            gridGap: "5px",
          }}
        >
          {images.map((image, idx) => {
            return (
              <Box key={idx} sx={{ position: "relative" }}>
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 0,
                    bgcolor: "white",
                    p: 0,
                    "&:hover": {
                      bgcolor: "white",
                    },
                  }}
                  onClick={() => removeImage(image.name)}
                >
                  <FiXCircle color="black" />
                </IconButton>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "scale-down",
                  }}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default ImagePreview;
