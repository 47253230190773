import React, { useState, useRef, useEffect } from "react";
import { FaWhatsapp, FaTwitter } from "react-icons/fa";

import Spinner from "../../shared/Spinner";

import { getUserId } from "../../shared/getUserId";
import { encryptReferralCode } from "../../utils/encryption_utils";

import {
  selectBitlyLinks,
  setBitlyLinks as storeBitlyLinks,
} from "../../redux/points/pointsSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

// mui
import { Stack, Typography, Collapse, Alert } from "@material-ui/core";

// custom component
import BackButton from "../Drawer/BackButton";

// QR code
import QRCode from "react-qr-code";
import TextInput from "../TextInput/TextInput";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import CustomIconButton from "../CustomButton/CustomIconButton";

// TODO: derive this from an array
const NUM_OF_LINK_SOURCES = 5;

const getLongLinks = () => {
  //  replace with generated url
  const linkSources: { [key: string]: any } = {
    copied: { referralCode: encryptReferralCode(getUserId(), "copied") },
    qr: { referralCode: encryptReferralCode(getUserId(), "QR") },
    whatsApp: { referralCode: encryptReferralCode(getUserId(), "whatsApp") },
    email: { referralCode: encryptReferralCode(getUserId(), "email") },
    twitter: { referralCode: encryptReferralCode(getUserId(), "twitter") },
  };

  // add unshorted links
  Object.keys(linkSources).forEach((key: string) => {
    linkSources[
      key
    ].unshortedLink = `https://${process.env.REACT_APP_AGAMA_APP_DOMAIN}/register?referral_code=${linkSources[key].referralCode}`;
  });

  return linkSources;
};

const Share: React.FC = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [bitlyLinks, setBitlyLinks] = useState<
    { [key: string]: string } | Record<string, never>
  >({});
  const [hasCompletedShortening, setHasCompletedShortening] = useState(false);

  // redux
  // TODO: Use redux only and not have this "handoff" happening
  const dispatch = useAppDispatch();
  const savedBitlyLinks = useAppSelector(selectBitlyLinks);

  // get bitly links
  useEffect(() => {
    if (Object.keys(bitlyLinks).length === NUM_OF_LINK_SOURCES) {
      setHasCompletedShortening(true);
      return;
    }

    const linkSources = getLongLinks();

    Object.keys(linkSources).forEach((key: string) =>
      (async () => {
        const response = await fetch("https://api-ssl.bitly.com/v4/shorten", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            long_url: linkSources[key].unshortedLink,
            domain: "agama.ws",
          }),
        });

        const json = await response.json();
        const { link } = json as { link: string };

        setBitlyLinks((links) => ({ ...links, [key]: link }));
      })()
    );
  }, []);

  useEffect(() => {
    const hasFinishedGenerating =
      Object.keys(bitlyLinks).length === NUM_OF_LINK_SOURCES;
    const hasAlreadyStoredLinks =
      Object.keys(savedBitlyLinks).length === NUM_OF_LINK_SOURCES;

    if (!hasAlreadyStoredLinks && hasFinishedGenerating) {
      dispatch(storeBitlyLinks({ bitlyLinks }));
      setHasCompletedShortening(true);
    }
  }, [bitlyLinks]);

  //  replace with text you want in message body for sharing on social media
  const shareText =
    "Hey! Come join us on Agama Africana, where we report live from our favorite places.";

  const copyLink = async () => {
    await navigator.clipboard.writeText(bitlyLinks?.copied || "");
    setOpenAlert(true);
    await setTimeout(() => setOpenAlert(false), 2000);
  };

  return hasCompletedShortening ? (
    <Stack justifyContent="space-between" sx={{ px: 5, height: "100%" }}>
      <Stack spacing={3}>
        <Stack alignItems="center">
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              fontWeight: 700,
              color: "text.primary",
            }}
          >
            Share Agama With Friends
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              fontWeight: 500,
              color: "text.primary",
            }}
          >
            Scan QR code
          </Typography>
          <QRCode size={200} value={bitlyLinks?.qr || ""} />
        </Stack>
        <Stack alignItems="center">
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              fontWeight: 500,
              color: "text.primary",
              marginBottom: 1,
            }}
          >
            Copy link and share
          </Typography>

          <Stack alignItems="center">
            <Collapse sx={{ width: "100%" }} in={openAlert}>
              <Alert>Link copied</Alert>
            </Collapse>
            <Stack alignItems="center" direction="row">
              <TextInput label="shareable link" value={bitlyLinks?.copied} />
              <CustomIconButton
                onClick={copyLink}
                icon={
                  <FontAwesomeIcon
                    style={{ color: "white" }}
                    icon={faClipboard}
                  />
                }
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="center">
          <Stack spacing={3} direction="row">
            <CustomIconButton
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `mailto:?subject=${shareText}&body=${shareText}%20on%20 ${bitlyLinks?.email}`
                );
              }}
              icon={
                <FontAwesomeIcon style={{ color: "white" }} icon={faEnvelope} />
              }
            />
            <CustomIconButton
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `whatsapp://send?text= ${shareText} %20 ${bitlyLinks?.whatsApp}`
                );
              }}
              icon={<FaWhatsapp color="white" />}
            />
            <CustomIconButton
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `https://twitter.com/intent/tweet?url= + ${bitlyLinks?.twitter} + &text= + ${shareText}`
                );
              }}
              icon={<FaTwitter color="white" />}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack alignItems="center">
        <BackButton />
      </Stack>
    </Stack>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
      }}
    >
      <div>
        <h2 style={{ fontFamily: "Helvetica", fontWeight: 400 }}>Loading</h2>
        <Spinner />
      </div>
    </div>
  );
};

export default Share;
