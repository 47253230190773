import styled from "@emotion/styled";

const UnstyledButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
`;

export default UnstyledButton;
