import React from "react";
import blankAvatarImg from "../../images/black_screen.png";
import images from "../../shared/images";

// mui
import { Avatar } from "@material-ui/core";

const ProfileAvatar: React.FC = () => {
  const blankAvatarImgPath =
    images.find((image) => image.id === "light_mode_avatar")?.path || "";
  return (
    <Avatar
      alt="profile image"
      src={blankAvatarImgPath}
      sx={{ width: "120px", height: "120px", bgcolor: "primary.main" }}
    />
  );
};

export default ProfileAvatar;
