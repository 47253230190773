/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import variables from "../shared/variables";
import { Point } from "../api/points";
import { Coordinate } from "../shared/types";
import { useLocation } from "react-router-dom";

// delasi drawer
import Drawer, {
  drawerPages,
  PATH_MATCHING_STRICT,
  PATH_MATCHING_REGEX,
} from "./Drawer/Drawer";

// custom components
import Map from "./Map/Map";
import Controls from "./Controls";

// redux
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { selectOpen, toggleDrawer } from "../redux/drawer/drawerSlice";

import ModalWebsite from "../screens/ModalWebsite/ModalWebsite";

const getMapCardHeight = (controlPanelHeight: number): number => {
  return 100 - controlPanelHeight;
};

const AgamaApp: React.FC = () => {
  const [viewportSize, setViewportSize] = useState<
    { height: number; width: number } | undefined
  >();
  const [mapCardHeight, setMapCardHeight] = useState(
    getMapCardHeight(variables.sizes.controlsCardInactiveHeight)
  );
  const [visiblePoints, setVisiblePoints] = useState<Point[]>([]);
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const [isDrawerDisabled, setIsDrawerDisabled] = useState(false);

  // TODO: map center (& map focus & isCenteredOnUser) not needed?
  const [mapCenter, setMapCenter] = useState<Coordinate>({
    x: 601.3125,
    y: 1047.96875,
  });
  const [mapFocus, setMapFocus] = useState<Coordinate>({
    x: 601.3125,
    y: 1047.96875,
  });
  const [isCenteredOnUser, setIsCenteredOnUser] = useState(true);

  // redux
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectOpen);

  // drawer contents
  const location = useLocation();
  const { pathname } = location;

  // Determines the component we're going to render in the drawer
  const currentPage = drawerPages.find((page) => {
    let isMatch;
    if (page.pathMatching === PATH_MATCHING_STRICT) {
      isMatch = pathname === page.path;
    } else if (page.pathMatching === PATH_MATCHING_REGEX) {
      const match = pathname.match(new RegExp(page.path || ""));
      isMatch = !!match;
    }

    return isMatch;
  });

  const DrawerContent = currentPage?.component;

  // whether to have the drawer open and the menu (modal website) hidden
  useEffect(() => {
    if (drawerPages.find((page) => page.path === pathname) && !isDrawerOpen) {
      if (pathname === "/") return; // todo: special case for the root path: we don't want the drawer open here

      dispatch(toggleDrawer(true));
      setIsMenuHidden(true);
    }
  }, [pathname]);

  const setScreenSize = () => {
    setViewportSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    setScreenSize();

    function resizeListener() {
      setScreenSize();
    }

    window.addEventListener("resize", resizeListener);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: viewportSize?.width ? `${viewportSize?.width}px` : 0,
        height: viewportSize?.height ? `${viewportSize?.height}px` : 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <Map
              mapCardHeight={mapCardHeight}
              setVisiblePoints={(pts: Point[]) => {
                setVisiblePoints([...pts]);
              }}
            />

            <Controls
              currentLocation={mapCenter}
              onRequestCentering={({ x, y }: Coordinate) =>
                setMapFocus({ x, y })
              }
              isUsersLocation={isCenteredOnUser}
              visiblePoints={visiblePoints}
              onOpenPanel={() => {
                setMapCardHeight(
                  getMapCardHeight(variables.sizes.controlsCardActiveHeight)
                );
              }}
              onClosePanel={() => {
                setMapCardHeight(
                  getMapCardHeight(
                    variables.sizes.controlsCardInactiveHeightHack
                  )
                );
              }}
              onHidePanel={() => {
                /* TODO: will need to expand map div when hiding controls, once mapbox integration is done. The do-nothing function is just a hack for speed. */
              }}
              isMenuHidden={isMenuHidden}
            />

            <Drawer
              isMenuButtonActive={isMenuHidden}
              onPanelOpened={() => setIsMenuHidden(true)}
              onPanelClosed={() => setIsMenuHidden(false)}
            >
              {DrawerContent ? <DrawerContent /> : null}
            </Drawer>

            <ModalWebsite
              onOpenPanel={() => {
                setIsDrawerDisabled(true);
              }}
              onClosePanel={() => {
                setIsDrawerDisabled(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgamaApp;
