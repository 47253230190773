import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface MusicObject {
  hasAskedMusicQuestion: boolean;
  hasAnsweredMusicQuestion: boolean;
  likesMusic: boolean | undefined;
  likesFourTet: boolean | undefined;
  isPlaying: boolean;
  returnToDefault: boolean;
}

interface NoPostsState {
  numEmptyPageLoads: number;
  musicObject: MusicObject;
}

const initialState: NoPostsState = {
  numEmptyPageLoads: 0,
  musicObject: {
    hasAskedMusicQuestion: false,
    hasAnsweredMusicQuestion: false,
    likesMusic: undefined,
    likesFourTet: undefined,
    returnToDefault: false,
    isPlaying: false,
  },
};

const noPostsSlice = createSlice({
  name: "noPosts",
  initialState,
  reducers: {
    setMusicObject: (state, { payload }) => {
      state.musicObject = payload;
    },
  },
});

// actions
export const { setMusicObject } = noPostsSlice.actions;

// state selectors
export const selectNumEmptyPageLoads = (state: RootState) =>
  state.noPosts.numEmptyPageLoads;
export const selectMusicObject = (state: RootState) =>
  state.noPosts.musicObject;

export default noPostsSlice.reducer;
