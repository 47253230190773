import { LayerProps } from "react-map-gl";

// cluster Layers
export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "posts",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": "#777",
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterLayerSelected: LayerProps = {
  id: "clusters-highlighted",
  type: "circle",
  source: "posts",
  filter: [
    "all",
    ["has", "point_count"],
    ["match", ["get", "cluster_id"], "", true, false],
  ],
  paint: {
    "circle-color": "#000",
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "posts",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
  paint: {
    "text-color": "#fff",
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "circle",
  source: "posts",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#777",
    "circle-radius": 8,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};

export const unclusteredLayerSelected: LayerProps = {
  id: "unclustered-point-highlighted",
  type: "circle",
  source: "posts",
  filter: [
    "all",
    ["!", ["has", "point_count"]],
    ["match", ["get", "id"], "", true, false],
  ],
  paint: {
    "circle-color": "#000",
    "circle-radius": 8,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};
