import React from "react";
import { IoLogoFacebook, IoLogoGoogle } from "react-icons/io5";
import ClipLoader from "react-spinners/ClipLoader";

// mui
import { Button } from "@mui/material";

interface ButtonProps {
  text: string;
  type?: string;
  onClick?: (event: React.FormEvent) => void;
  isDisabled?: boolean;
  loading?: boolean;
}

// custom button component
const CustomButton: React.FC<ButtonProps> = ({
  text,
  type,
  onClick,
  isDisabled,
  loading,
}) => {
  const isGoogle = type === "google";
  const isFacebook = type === "facebook";

  let icon = null;
  let bgColor = "primary.main";

  if (isGoogle) {
    icon = <IoLogoGoogle />;
    bgColor = "google.main";
  }

  if (isFacebook) {
    icon = <IoLogoFacebook />;
    bgColor = "facebook.main";
  }

  return (
    <Button
      variant="contained"
      disableRipple
      disableElevation
      startIcon={icon}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        width: {
          xs: "250px",
          sm: "300px",
          md: "300px",
          lg: "300px",
          xl: "300px",
        },
        height: "50px",
        borderRadius: "10px",
        bgcolor: `${bgColor}`,
        "&:hover": {
          bgcolor: `${bgColor}`,
        },
        fontSize: {
          xs: "12px",
          sm: "14px",
          md: "15px",
          lg: "15px",
          xl: "15px",
        },
      }}
    >
      {loading ? <ClipLoader color="#fff" size={20} /> : text}
    </Button>
  );
};

export default CustomButton;
