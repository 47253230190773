import React from "react";
import { IoLogoFacebook, IoLogoGoogle } from "react-icons/io5";
import ClipLoader from "react-spinners/ClipLoader";

// mui
import { IconButton } from "@mui/material";

interface ButtonProps {
  icon: React.ReactChild;
  type?: string;
  onClick?: (event: React.FormEvent) => void;
  isDisabled?: boolean;
  loading?: boolean;
}

// custom button component
const CustomIconButton: React.FC<ButtonProps> = ({
  icon,
  type,
  onClick,
  isDisabled,
}) => {
  return (
    <IconButton
      disableRipple
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        borderRadius: "10px",
        bgcolor: "primary.main",
        "&:hover": {
          bgcolor: "primary.main",
        },
        fontSize: {
          xs: "12px",
          sm: "14px",
          md: "15px",
          lg: "15px",
          xl: "15px",
        },
      }}
    >
      {icon}
    </IconButton>
  );
};

export default CustomIconButton;
