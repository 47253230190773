import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import variables from "../../shared/variables";
import AgamaHeader from "../../shared/AgamaHeader";

// redux
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectOpen, toggleDrawer } from "../../redux/drawer/drawerSlice";

import { useNavigate } from "react-router-dom";

// mui
import { Drawer as MUIDrawer, IconButton, Box } from "@material-ui/core";

import DrawerMenu from "./DrawerMenu";
import UserProfile from "../UserProfile/UserProfile";
import LoginRegister from "../LoginRegister/LoginRegister";
import ResetPassword from "../ResetPassword/ResetPassword";
import Share from "../Share/Share"; // "../Share/Share";
import AddNewSuggestion from "../SuggestFeature/AddNewSuggestion";
import SuggestFeature from "../SuggestFeature";
import VoteForSuggestion from "../SuggestFeature/VoteForSuggestion";
import SuggestionDetails from "../SuggestFeature/SuggestionDetails";

interface DrawerProps {
  isMenuButtonActive: boolean;
  onPanelOpened: () => void;
  onPanelClosed: () => void;
}

export const PATH_MATCHING_STRICT = "STRICT" as const;
export const PATH_MATCHING_REGEX = "REGEX" as const;

/* Todo: Merge DrawerPage w/ the Page type in ModalWebsite */
interface DrawerPage {
  title: string;
  path: string | null;
  pathMatching: typeof PATH_MATCHING_STRICT | typeof PATH_MATCHING_REGEX;
  component: React.FunctionComponent;
}

export const drawerPages: DrawerPage[] = [
  {
    title: "home",
    path: "/",
    pathMatching: PATH_MATCHING_STRICT,
    component: DrawerMenu,
  },
  {
    title: "login",
    path: "/login",
    pathMatching: PATH_MATCHING_STRICT,
    component: LoginRegister,
  },
  {
    title: "register",
    path: "/register",
    pathMatching: PATH_MATCHING_STRICT,
    component: LoginRegister,
  },
  {
    title: "user profile",
    path: "/user-profile",
    pathMatching: PATH_MATCHING_STRICT,
    component: UserProfile,
  },
  {
    title: "reset password",
    path: "/reset-password",
    pathMatching: PATH_MATCHING_STRICT,
    component: ResetPassword,
  },
  {
    title: "share",
    path: "/share",
    pathMatching: PATH_MATCHING_STRICT,
    component: Share,
  },
  {
    title: "suggest a feature",
    path: "/suggest-a-feature",
    pathMatching: PATH_MATCHING_STRICT,
    component: SuggestFeature,
  },
  {
    title: "add new suggestion",
    path: "/suggest-a-feature/add",
    pathMatching: PATH_MATCHING_STRICT,
    component: AddNewSuggestion,
  },
  {
    title: "vote for suggestion",
    path: "/suggest-a-feature/vote",
    pathMatching: PATH_MATCHING_STRICT,
    component: VoteForSuggestion,
  },
  {
    title: "user profile",
    path: "/suggest-a-feature/details/*",
    pathMatching: PATH_MATCHING_REGEX,
    component: SuggestionDetails,
  },
];

const Drawer: React.FC<DrawerProps> = ({
  onPanelOpened,
  onPanelClosed,
  isMenuButtonActive,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDrawerOpen = useAppSelector(selectOpen);

  const onDrawerOpen = () => {
    dispatch(toggleDrawer(true));
    onPanelOpened();
  };

  const onDrawerClose = () => {
    navigate("/");
    dispatch(toggleDrawer(false));
    onPanelClosed();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "0.4rem",
          right: "0.5rem",
          bgcolor: "white",
          borderRadius: "100%",
          height: "40px",
          width: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: {
            xs: isMenuButtonActive
              ? variables.numbers.overModalZIndex
              : variables.numbers.underModalZIndex,
            sm: isMenuButtonActive
              ? variables.numbers.overModalZIndex
              : variables.numbers.underModalZIndex,
          },
        }}
      >
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={isDrawerOpen ? onDrawerClose : onDrawerOpen}
          sx={{
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          {isDrawerOpen ? (
            <FaTimes color="#000" size={16} title="Close menu" />
          ) : (
            <FaBars color="#000" size={16} title="Open menu" />
          )}
        </IconButton>
      </Box>

      <MUIDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={onDrawerClose}
        /* this any is necessary, as PaperProps doesn't contain "data-testid" */
        PaperProps={{ "data-testid": "side-card" } as any}
      >
        <Box
          sx={{
            width: {
              xs: "330px",
              sm: "400px",
              md: "500px",
              lg: "1000px",
              xl: "1000px",
            },
            height: "100vh",
          }}
        >
          <AgamaHeader marginTop="50px" marginBottom="25px" />
          <Box
            sx={{
              height: "81%",
              px: {
                xs: 3,
                sm: 3,
                md: 4,
                lg: 5,
                xl: 5,
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </MUIDrawer>
    </>
  );
};

export default Drawer;
