/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { trackEvent } from "../../analytics/SegmentAnalytics";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// components
import UnstyledButton from "../../shared/UnstyledButton";

const Header: React.FC<{
  onSetSelected: (arg0: string) => any;
  selectedTab: string;
}> = ({ selectedTab, onSetSelected }) => {
  const user = useAppSelector(selectUser);

  const [selected, setSelected] = useState(selectedTab);

  const onClickButton = (buttonName: string) => {
    setSelected(buttonName);
    onSetSelected(buttonName);
  };

  useEffect(() => {
    setSelected(selectedTab);
  }, [selectedTab]);

  return (
    <div
      style={{
        borderBottom: "3px dotted #888",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          width: 100%;
          max-width: 500px;
          padding: 0 1rem 0.7rem 1rem;

          li {
            text-decoration: none;
            list-style-type: none;
          }

          button {
            position: relative;
          }

          button:before {
            content: "";
            background-color: black;
            height: 3px;
            width: 0;
            position: absolute;
            bottom: -0.3rem;
            left: 0;
            transition: all 0.4s;
            border-radius: 5px;
          }

          button.selected:before {
            width: 100%;
          }
        `}
      >
        <li>
          <UnstyledButton
            style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
            onClick={() => onClickButton("ask")}
            className={selected === "ask" ? "selected" : ""}
          >
            &nbsp;Ask&nbsp;
          </UnstyledButton>
        </li>
        <li>
          <UnstyledButton
            style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
            onClick={() => onClickButton("answer")}
            className={selected === "answer" ? "selected" : ""}
          >
            &nbsp;Answer&nbsp;
          </UnstyledButton>
        </li>
        <li>
          <UnstyledButton
            style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
            onClick={() => {
              onClickButton("browse");
              trackEvent("User browses questions", {
                username: user?.username,
              });
            }}
            className={selected === "browse" ? "selected" : ""}
          >
            &nbsp;Browse&nbsp;
          </UnstyledButton>
        </li>
      </ul>
    </div>
  );
};

export default Header;
