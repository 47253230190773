import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";

import { db } from "../../firebase/firebase-config";

import { User } from "../../redux/user/userSlice";

import { Message } from "../../api/points";

export const allCutoffTimes = [
  1647947587546, // Tue 22 Mar 11:13 AM
  1647415269632, // Wed 16 Mar 7:21 AM
  1646724574759, // Tue 8 Mar 7:30 AM
  1646084432543, // Mon 28 Feb 9:40 PM
];

export interface AmbassadorData {
  successfulReferrals: User[];
  posts: any[];
}

export const getPosts = async (userIds: string[], cutoffTimes?: number[]) => {
  const colRef = collection(db, "points");
  const snapshot = await getDocs(colRef);

  let posts: any[] = [];

  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    const messages = data.messages;

    const usersMessages = messages.filter((msg: Message) => {
      const isBeforeCutoff =
        cutoffTimes && cutoffTimes.length === 2
          ? cutoffTimes[0] < +new Date(msg.time) &&
            +new Date(msg.time) <= cutoffTimes[1]
          : true;
      return userIds.includes(msg.userId || "") && isBeforeCutoff;
    });

    if (usersMessages.length > 0) {
      posts = posts.concat(usersMessages);
    }
  });

  // const q = query(colRef, where("userId", "==", userId));

  // const querySnapshot = await getDocs(q);
  // querySnapshot.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   console.log(doc.id, " => ", doc.data());
  // });

  // return posts;

  return posts;
};

export const getAmbassadorData = async (
  userId: string
): Promise<AmbassadorData> => ({
  successfulReferrals: [],
  posts: await getPosts([userId], [allCutoffTimes[1], allCutoffTimes[0]]),
});
