import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactMapGL, {
  GeolocateControl,
  Source,
  Layer,
  WebMercatorViewport,
  Marker,
} from "react-map-gl";
import { debounce } from "throttle-debounce";
import variables from "../../shared/variables";
import { Point } from "../../api/points";
import {
  clusterLayer,
  clusterLayerSelected,
  clusterCountLayer,
  unclusteredPointLayer,
  unclusteredLayerSelected,
} from "./clusterLayers";

import currentLocationPin from "../../images/current_location_icon.png";

// firebase
import { db } from "../../firebase/firebase-config";
import { collection, query, onSnapshot, where } from "firebase/firestore";

// redux
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectPosts,
  selectQueries,
  selectCurrentView,
  setPosts,
  setQueries,
  setCurrentLocation,
} from "../../redux/points/pointsSlice";

const MAPBOX_STYLE = "mapbox://styles/mapbox/light-v10";

interface MapProps {
  mapCardHeight: number;
  setVisiblePoints: (points: Point[]) => void;
}

// map bounding box type
interface Bounds {
  topLeft: { lat: number; lng: number };
  bottomRight: { lat: number; lng: number };
}

const Map: React.FC<MapProps> = ({ mapCardHeight, setVisiblePoints }) => {
  const dispatch = useAppDispatch();

  const posts = useAppSelector(selectPosts);
  const queries = useAppSelector(selectQueries);
  const currentView = useAppSelector(selectCurrentView);

  const mapRef = useRef(null);

  const [points, setPoints] = useState<GeoJSON.FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });
  const [isMapMoved, setIsMapMoved] = useState<boolean>(true);
  // viewport bounds
  const [bounds, setBounds] = useState<Bounds | null>(null);

  // Map Variables
  const [viewport, setViewport] = useState({
    latitude: 1.0232,
    longitude: 7.9465,
    zoom: 5,
    bearing: 0,
    pitch: 0,
    transitionDuration: 500,
  });
  const [markerCameraCenter, setMarkerCameraCenter] = useState({
    latitude: 1.0232,
    longitude: 7.9465,
  });
  // used to auto geolocate and to trigger a re-centering on user
  const [isAutoGeoLocating, setIsAutoGeoLocating] = useState<boolean>(true);
  // ref to avoid stale state in onGeolocate
  const refCurrentView = useRef(currentView);

  const transformData = (pointId: string, data: any) => {
    const { x, y, messages, id, type } = data;
    const message = messages[0];

    return {
      pointId,
      x,
      y,
      id,
      type,
      messages: [
        {
          pointId,
          likes: message.likes,
          likesArray: message.likesArray ? message.likesArray : [],
          location: message.location,
          message: message.message,
          time: message.time,
          uid: message.uid,
          userId: message.userId,
          username: message.username,
          profileImage: message.profileImage ? message.profileImage : "",
          images: message.images ? message.images : [],
          video: message.video ? message.video : "",
          comments: message.comments ? message.comments : [],
        },
      ],
    };
  };

  // TODO: this should also filter out stuff based on the time and topics
  const filterData = (data: any, boundingBox: any) => {
    return data.filter((point: any) => {
      // TODO: point should have only one message, not an array
      const message = point.messages[0];
      const messageTime = new Date(message.time);
      const aDayAgo = new Date(+new Date() - 1000 * 60 * 60 * 24);

      const isInBoundingBox =
        point.x <= boundingBox.topLeft.lat &&
        point.y <= boundingBox.topLeft.lng &&
        point.x >= boundingBox.bottomRight.lat &&
        point.y >= boundingBox.bottomRight.lng;
      const isTimely = messageTime > aDayAgo;

      if (isInBoundingBox && isTimely) {
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    const pointsQuery = query(
      collection(db, "points"),
      where("type", "==", "Post"),
      where("x", "<=", bounds && bounds.topLeft.lat),
      where("x", ">=", bounds && bounds.bottomRight.lat)
    );

    const queriesQuery = query(
      collection(db, "points"),
      where("type", "==", "Query"),
      where("x", "<=", bounds && bounds.topLeft.lat),
      where("x", ">=", bounds && bounds.bottomRight.lat)
    );

    // fetch new points from firestore whenever mapview changes
    const unsubscribeFromPosts = onSnapshot(pointsQuery, (querySnapshot) => {
      const data = querySnapshot.docs.map((postDoc) => {
        return transformData(postDoc.id, postDoc.data());
      });

      const postPoints = filterData(data, bounds);
      dispatch(setPosts(postPoints));
    });

    // fetch new queries when viewport changes
    const unsubscribeFromQueries = onSnapshot(queriesQuery, (querySnapshot) => {
      const data = querySnapshot.docs.map((queryDoc) => {
        return transformData(queryDoc.id, queryDoc.data());
      });

      const queryPoints = filterData(data, bounds);
      dispatch(setQueries(queryPoints));
    });

    return () => {
      unsubscribeFromPosts();
      unsubscribeFromQueries();
    };
  }, [bounds]);

  // sets new bounding box coordinates everytime viewport changes
  const handleBoundsChange = (vp: any) => {
    const [bottomRight, topLeft] = new WebMercatorViewport(vp).getBounds();

    if (bottomRight && topLeft) {
      setBounds({
        topLeft: { lat: topLeft[1], lng: topLeft[0] },
        bottomRight: { lat: bottomRight[1], lng: bottomRight[0] },
      });
    }
  };

  useEffect(() => {
    let listType;

    if (currentView === "Query") {
      listType = queries;
    } else {
      listType = posts;
    }

    const pe: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: listType.map((point, i) => {
        return {
          type: "Feature",
          properties: {
            id: point.id,
            type: point.type,
          },
          geometry: {
            type: "Point",
            coordinates: [point.y, point.x],
          },
        };
      }),
    };

    setPoints(pe);
  }, [currentView, posts, queries]);

  const onClick = (event: any) => {
    const feature = event.features[0];

    if (!feature) {
      return;
    }

    setIsMapMoved(true);

    const map = (mapRef?.current as any).getMap();

    if (feature.properties.cluster) {
      // it is a cluster that was clicked
      const clusterId = feature.properties.cluster_id;

      if (mapRef) {
        const mapboxSource = map.getSource("posts");

        mapboxSource.getClusterExpansionZoom(
          clusterId,
          (err: Error, zoom: number) => {
            if (err) {
              return;
            }

            const pointCount = feature.properties.point_count;

            // Get Next level cluster Children
            mapboxSource.getClusterChildren(
              clusterId,
              (error: Error, features: GeoJSON.Feature[]) => {
                if (!error) {
                  const clusterIds: number[] = [];
                  for (const childFeature of features) {
                    if (childFeature?.properties?.cluster) {
                      if (
                        !clusterIds.includes(childFeature.properties.cluster_id)
                      ) {
                        clusterIds.push(childFeature.properties.cluster_id);
                      }
                    }
                  }

                  if (clusterIds.length > 0) {
                    map.setFilter("clusters-highlighted", [
                      "all",
                      ["has", "point_count"],
                      ["match", ["get", "cluster_id"], clusterIds, true, false],
                    ]);
                  } else {
                    map.setFilter("clusters-highlighted", [
                      "all",
                      ["has", "point_count"],
                      ["match", ["get", "cluster_id"], "", true, false],
                    ]);
                  }
                }
              }
            );

            // Get all points under a cluster
            mapboxSource.getClusterLeaves(
              clusterId,
              pointCount,
              0,
              (error: Error, features: GeoJSON.Feature[]) => {
                if (!error) {
                  const pointsID = features.map(
                    (fPoint) => fPoint?.properties?.id
                  );
                  const clusterPoints = posts.filter((point) =>
                    pointsID.includes(point.id)
                  );

                  // Set a filter matching selected features by id codes
                  // to activate the 'clusters-highlighted' and unclustered-point-highlighted layer.
                  if (pointsID.length > 0) {
                    map.setFilter("unclustered-point-highlighted", [
                      "all",
                      ["!", ["has", "point_count"]],
                      ["match", ["get", "id"], pointsID, true, false],
                    ]);
                  } else {
                    map.setFilter("unclustered-point-highlighted", [
                      "all",
                      ["!", ["has", "point_count"]],
                      ["match", ["get", "id"], "", true, false],
                    ]);
                  }

                  // get the point and set them as selected
                  setPosts(clusterPoints);
                }
              }
            );

            // zoom the screen
            setViewport({
              ...viewport,
              longitude: feature.geometry.coordinates[0],
              latitude: feature.geometry.coordinates[1],
              zoom,
              transitionDuration: 500,
            });
          }
        );
      }
    } else {
      // it is a point that was clicked
      const properties = feature.properties;
      const pointClicked = posts.find((point) => point.id === properties.id);
      if (pointClicked) {
        setVisiblePoints([pointClicked]);
        // unhighlight the clusters
        map.setFilter("clusters-highlighted", [
          "all",
          ["has", "point_count"],
          ["match", ["get", "cluster_id"], "", true, false],
        ]);
        // highlight the point
        map.setFilter("unclustered-point-highlighted", [
          "all",
          ["!", ["has", "point_count"]],
          ["match", ["get", "id"], [properties.id], true, false],
        ]);
      }
    }
  };

  const getPointsFromMapCluster = (
    mapboxSource: any,
    feature: any
  ): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      mapboxSource.getClusterLeaves(
        feature.properties.cluster_id,
        feature.properties.point_count,
        0,
        (error: Error, featurePoints: GeoJSON.Feature[]) => {
          if (!error) {
            const pointsID = featurePoints.map((fPoint) => fPoint?.properties);
            resolve(pointsID);
          } else {
            resolve([]);
          }
        }
      );
    });
  };

  const getUniquePointsFromFeatures = async (
    features: any
  ): Promise<{ pointIds: number[]; clusterIds: number[] }> => {
    const uniqueClusterIds = new Set<number>();
    const foundPointsIds = new Set<number>();

    const map = (mapRef?.current as any).getMap();
    const mapboxSource = map.getSource("posts");

    for (const feature of features) {
      if (feature.properties.cluster) {
        const clusterId = feature.properties.cluster_id;
        if (!uniqueClusterIds.has(clusterId)) {
          uniqueClusterIds.add(clusterId);
          const clusterPoints = await getPointsFromMapCluster(
            mapboxSource,
            feature
          );
          for (const clusterPoint of clusterPoints) {
            const pointId = clusterPoint.id;
            if (!foundPointsIds.has(pointId)) {
              foundPointsIds.add(pointId);
            }
          }
        }
      } else {
        const pointId = feature.properties.id;
        if (!foundPointsIds.has(pointId)) {
          foundPointsIds.add(pointId);
        }
      }
    }

    return { pointIds: [...foundPointsIds], clusterIds: [...uniqueClusterIds] };
  };

  const onMapScrolled = async (shouldSkip: boolean) => {
    if (!shouldSkip) {
      const map = (mapRef?.current as any).getMap();
      const featuresMap = map.queryRenderedFeatures({
        layers: ["clusters", "unclustered-point"],
      });

      if (featuresMap) {
        const { pointIds, clusterIds } = await getUniquePointsFromFeatures(
          featuresMap
        );
        const pointsInView = posts.filter((point) =>
          pointIds.includes(point.id)
        );
        setVisiblePoints(pointsInView);

        // highlight the clusters
        if (clusterIds.length > 0) {
          map.setFilter("clusters-highlighted", [
            "all",
            ["has", "point_count"],
            ["match", ["get", "cluster_id"], clusterIds, true, false],
          ]);
        } else {
          map.setFilter("clusters-highlighted", [
            "all",
            ["has", "point_count"],
            ["match", ["get", "cluster_id"], "", true, false],
          ]);
        }
        // highlight the points
        if (pointIds.length > 0) {
          map.setFilter("unclustered-point-highlighted", [
            "all",
            ["!", ["has", "point_count"]],
            ["match", ["get", "id"], pointIds, true, false],
          ]);
        } else {
          map.setFilter("unclustered-point-highlighted", [
            "all",
            ["!", ["has", "point_count"]],
            ["match", ["get", "id"], "", true, false],
          ]);
        }
      }
    } else {
      setIsMapMoved(false);
    }
  };

  const debounceOnMapScrolled = useCallback(
    debounce(300, (shouldSkip: boolean) => {
      (async () => onMapScrolled(shouldSkip))();
    }),
    []
  );

  const debouncedBoundsChanged = useCallback(
    debounce(300, (vp: any, isViewQuery: boolean) => {
      handleBoundsChange(vp);
      // if Query then current location is where user scrolls
      if (isViewQuery) {
        dispatch(
          setCurrentLocation({
            lat: vp.latitude,
            lng: vp.longitude,
          })
        );
      }
    }),
    []
  );

  const onViewportChange = (vp: any) => {
    setViewport(vp);
    setMarkerCameraCenter({
      latitude: vp.latitude,
      longitude: vp.longitude,
    });
    debouncedBoundsChanged(vp, currentView === "Query");
  };

  const onGeolocate = (props: any) => {
    // if not Query then current location is user's location
    if (refCurrentView.current !== "Query") {
      dispatch(
        setCurrentLocation({
          lat: props.coords.latitude,
          lng: props.coords.longitude,
        })
      );
    }
  };

  const mapHeightWithUnits = `${mapCardHeight}${variables.sizes.controlsCardHeightUnit}`;

  useEffect(() => {
    // ref to avoid stale state in onGeolocate
    refCurrentView.current = currentView;
    // todo: make these views constants (misspelling)
    if (currentView === "Post") {
      setIsAutoGeoLocating(false);
      setTimeout(() => {
        setIsAutoGeoLocating(true);
      }, 100);
    }
  }, [currentView]);

  return (
    <ReactMapGL
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      {...viewport}
      width="100%"
      height={mapHeightWithUnits}
      mapStyle={MAPBOX_STYLE}
      onViewportChange={onViewportChange}
      attributionControl={false}
      interactiveLayerIds={[
        clusterLayer.id || "",
        unclusteredPointLayer.id || "",
      ]}
      onClick={onClick}
      onViewStateChange={() => debounceOnMapScrolled(isMapMoved)}
      onLoad={() => setIsMapMoved(false)}
      ref={mapRef}
    >
      {currentView === "Query" ? (
        <Marker
          longitude={markerCameraCenter.longitude}
          latitude={markerCameraCenter.latitude}
        >
          <img src={currentLocationPin} style={{ width: 20, height: 20 }} />
        </Marker>
      ) : null}
      <GeolocateControl
        style={{
          bottom: 0,
          right: 0,
          margin: 10,
        }}
        trackUserLocation={true}
        positionOptions={{
          enableHighAccuracy: true,
        }}
        showUserHeading={true}
        auto={isAutoGeoLocating}
        showAccuracyCircle={false}
        onGeolocate={onGeolocate}
      />
      <Source
        id="posts"
        type="geojson"
        data={points}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterLayerSelected} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
        <Layer {...unclusteredLayerSelected} />
      </Source>
    </ReactMapGL>
  );
};

export default Map;
