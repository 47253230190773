import React from "react";

import { NavLink } from "react-router-dom";

// mui
import { Link } from "@material-ui/core";

interface MenuLinkProps {
  to: string;
  text: string;
  onClick?: () => void;
}

const DrawerMenuLink: React.FC<MenuLinkProps> = ({ text, to, onClick }) => {
  return (
    <div>
      <Link
        underline="none"
        component={NavLink}
        to={to}
        onClick={onClick}
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          fontFamily: "Helvetica",
        }}
      >
        {text}
      </Link>
    </div>
  );
};

export default DrawerMenuLink;
