import React, { useState, useEffect } from "react";
import variables from "../../shared/variables";
import { ClipLoader } from "react-spinners";
import { Message, Point } from "../../api/points";
import { trackEvent } from "../../analytics/SegmentAnalytics";

// firebase
import { addNewPoint } from "../../firebase/points";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectCurrentLocation } from "../../redux/points/pointsSlice";
import { selectUser } from "../../redux/user/userSlice";

// mui
import { Button as MUIButton, TextField } from "@material-ui/core";

// components
import DropDown from "../../shared/DropDown";
import CustomAlert from "../CustomAlert/CustomAlert";

interface AskTabProps {
  onCreatePost: () => void;
  locations: string[];
}

const AskTab: React.FC<AskTabProps> = ({ onCreatePost, locations }) => {
  const user = useAppSelector(selectUser);
  const currentLocation = useAppSelector(selectCurrentLocation);

  const [location, setLocation] = useState(locations[0]);
  const [msg, setMsg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSelectLocation = (selectedLocation: string) => {
    setLocation(selectedLocation);
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  const onSubmit = async () => {
    if (!user) {
      setErrorMessage("You need to be signed in to ask questions");
      return;
    }

    trackEvent("User asked question", {
      username: user?.username,
    });

    const msgObj: Message = {
      message: msg,
      time: new Date().toISOString(),
      uid: 0,
      location,
      likes: 0,
      likesArray: [],
      username: user.username,
      userId: user.id,
    };

    const pointObj: Point = {
      id: +new Date(),
      x: currentLocation.lat,
      y: currentLocation.lng,
      type: "Query",
      messages: [msgObj],
    };

    setLoading(true);
    await addNewPoint(pointObj);
    setLoading(false);

    onCreatePost();
  };

  useEffect(() => {
    if (!user) {
      setErrorMessage("You need to be signed in to ask questions");
    }
  }, [user]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          padding: "0.6rem 1rem 1rem 1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            padding: "0 0rem 0.7rem 0",
            fontFamily: variables.fonts.body,
          }}
        >
          Ask about
        </div>
        <DropDown
          size="small"
          variant="standard"
          onSelect={onSelectLocation}
          options={locations}
        >
          {location}
        </DropDown>
      </div>
      <div style={{ marginTop: "3rem", padding: "0 1.3rem" }}>
        <TextField
          id="post-textarea"
          variant="standard"
          placeholder="What would you like to know"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          multiline
          fullWidth
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem 0 1.5rem 0",
        }}
      >
        <MUIButton
          variant="contained"
          disableElevation
          disableRipple
          sx={{
            textTransform: "capitalize",
            bgcolor: "primary.main",
            color: "white",
            padding: "0.4rem 1rem",
            fontWeight: 700,
            borderRadius: "1rem",
            border: "none",
          }}
          onClick={onSubmit}
          size="small"
          disabled={!user || msg === ""}
        >
          {loading ? <ClipLoader color="#fff" size={20} /> : "Ask"}
        </MUIButton>
      </div>

      <CustomAlert
        onClose={handleCloseErrorAlert}
        severity="error"
        message={errorMessage}
      />
    </div>
  );
};

export default AskTab;
