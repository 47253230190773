/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { trackEvent } from "../../analytics/SegmentAnalytics";
import { Message, Point } from "../../api/points";
import { Coordinate } from "../../shared/types";
import ClipLoader from "react-spinners/ClipLoader";

import { IoImagesOutline, IoVideocamOutline } from "react-icons/io5";

// firebase
import { addNewPoint, uploadImages, uploadVideo } from "../../firebase/points";

// redux
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";
import {
  setCurrentView,
  selectCurrentLocation,
} from "../../redux/points/pointsSlice";

// mui
import {
  Button as MUIButton,
  TextField,
  IconButton,
  Typography,
  Stack,
  Tooltip,
  Snackbar,
  Alert,
} from "@material-ui/core";

// components
import CustomAlert from "../CustomAlert/CustomAlert";
import DropDown from "../../shared/DropDown";
import ImagePreview from "../ImagePreview/ImagePreview";
import VideoPreview from "../VideoPreview/VideoPreview";

interface PostPanelParams {
  onCreatePost: () => void;
  locations: string[];
  currentLocation: Coordinate;
}

const PostPanel: React.FC<PostPanelParams> = ({ onCreatePost, locations }) => {
  const user = useAppSelector(selectUser);

  const [location, setLocation] = useState(locations[0]);
  const [msg, setMsg] = useState("");
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadMsg, setUploadMsg] = useState("");

  // redux
  const dispatch = useAppDispatch();
  const currentLocation = useAppSelector(selectCurrentLocation);

  const onSelectLocation = (selectedLocation: string) => {
    setLocation(selectedLocation);
  };

  const handleCloseUploadMsgAlert = () => {
    setUploadMsg("");
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  const removeImage = (name: string) => {
    const newImages = imageFiles.filter((image) => image.name !== name);
    setImageFiles(newImages);
  };

  const onSubmit = async () => {
    if (!user) {
      setErrorMessage("You need to be signed in to create posts");
      return;
    }

    setLoading(true);

    let images: string[] = [];
    let video = "";

    try {
      if (imageFiles.length > 0) {
        const imageUrls = await uploadImages(imageFiles, user);
        images = imageUrls;
      }

      if (videoFile) {
        const videoUrl = await uploadVideo(videoFile, user);
        video = videoUrl;
      }

      const msgObj: Message = {
        message: msg,
        time: new Date().toISOString(),
        uid: 0,
        location,
        likes: 0,
        likesArray: [],
        username: user.username,
        userId: user.id,
        images,
        video,
        profileImage: user.profileImage,
      };

      const pointObj: Point = {
        id: +new Date(),
        x: currentLocation.lat,
        y: currentLocation.lng,
        type: "Post",
        messages: [msgObj],
      };

      await addNewPoint(pointObj);
      setLoading(false);

      onCreatePost();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setCurrentView("Post"));
  }, []);

  useEffect(() => {
    if (!user) {
      setErrorMessage("You need to be signed in to create posts");
    }
  }, [user]);

  return (
    <Stack sx={{ width: "100%", height: "100%", pb: "20px" }} spacing={2}>
      {/* location dropdown */}
      <Stack
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        direction="row"
      >
        <Typography>Post From</Typography>

        <DropDown
          size="small"
          variant="standard"
          onSelect={onSelectLocation}
          options={locations}
        >
          {location}
        </DropDown>
      </Stack>

      {/* message textfield */}
      <TextField
        id="post-textarea"
        variant="standard"
        placeholder="What's going on?"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        multiline
        fullWidth
        maxRows={1}
      />

      {/* image previews */}
      <ImagePreview images={imageFiles} removeImage={removeImage} />

      {/* video preview */}
      {videoFile && (
        <VideoPreview
          videoFile={videoFile}
          removeVideo={() => setVideoFile(null)}
        />
      )}

      {/* post button and image upload button */}
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={{ pb: "10px" }}
      >
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <Tooltip title="upload images">
            <label htmlFor="images">
              <input
                style={{ display: "none" }}
                accept="image/*"
                id="images"
                type="file"
                multiple
                onChange={(event) => {
                  const { files } = event?.target as HTMLInputElement;
                  if (!files?.length) return;

                  if (files?.length > 4) {
                    setUploadMsg("Limit is 4 images");
                    return;
                  }

                  const imageFilesArray = [];

                  for (let i = 0; i < files.length; i++) {
                    imageFilesArray.push(files[i]);
                  }

                  setImageFiles([...imageFilesArray]);
                  setVideoFile(null);
                }}
              />

              <IconButton component="span">
                <IoImagesOutline size="1.5rem" />
              </IconButton>
            </label>
          </Tooltip>

          <Tooltip title="upload video">
            <label htmlFor="video">
              <input
                type="file"
                id="video"
                style={{ display: "none" }}
                accept="video/*"
                onChange={(event) => {
                  const { files } = event?.target as HTMLInputElement;
                  const file = files && files[0];

                  if (file && file.size > 5 * 1024 * 1024) {
                    setUploadMsg("Video too large. Max 5MB");
                    return;
                  }

                  setVideoFile(file);
                  setImageFiles([]);
                }}
                size={3000000}
              />

              <IconButton component="span">
                <IoVideocamOutline size="1.5rem" />
              </IconButton>
            </label>
          </Tooltip>
        </Stack>

        <MUIButton
          variant="contained"
          disableElevation
          disableRipple
          sx={{
            textTransform: "capitalize",
            bgcolor: "primary.main",
            color: "white",
            padding: "0.4rem 1rem",
            fontWeight: 700,
            borderRadius: "1rem",
            border: "none",
          }}
          onClick={() => {
            onSubmit();
            trackEvent("User posts announcement", {
              username: user?.username,
            });
          }}
          size="small"
          disabled={
            !user ||
            (msg === "" && imageFiles.length === 0 && videoFile === null)
          }
        >
          {loading ? <ClipLoader color="#fff" size={20} /> : "Let them know"}
        </MUIButton>
      </Stack>

      <CustomAlert
        onClose={handleCloseErrorAlert}
        severity="error"
        message={errorMessage}
      />

      <Snackbar
        open={Boolean(uploadMsg)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={2000}
        onClose={handleCloseUploadMsgAlert}
        sx={{
          zIndex: 3000,
        }}
      >
        <Alert
          onClose={handleCloseUploadMsgAlert}
          severity="error"
          variant="outlined"
          sx={{ width: "100%" }}
        >
          <Typography sx={{ textTransform: "capitalize" }}>
            {uploadMsg}
          </Typography>
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default PostPanel;
