export function trackEvent(
  eventDescription: string,
  properties: { [key: string]: string | undefined }
): void {
  window.analytics.track(eventDescription, properties);
}

export function trackIdentity(
  userId: string,
  properties: {
    username?: string | undefined;
    email?: string | undefined;
    uid?: string | undefined;
  }
): void {
  window.analytics.identify(userId, properties);
}
