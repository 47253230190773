import { Link } from "@mui/material";
import { NavLink } from "react-router-dom";

import {
  modalWebsitePages,
  HEADER_LOCATION,
  FOOTER_LOCATION,
} from "../ModalWebsite";
import AgamaHeader from "../../../shared/AgamaHeader";
import variables from "../../../shared/variables";
import toSentenceCase from "../helpers/toSentenceCase";

const NavTemplate: React.FC = ({ children }) => {
  return (
    <nav style={{ fontFamily: variables.fonts.body }}>
      <ul
        style={{
          margin: "10px 0",
          padding: "0 10px",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          listStyleType: "none",
          fontWeight: 100,
        }}
      >
        {children}
      </ul>
    </nav>
  );
};

const DesktopTemplate: React.FC = ({ children }) => {
  const headerListElems: React.ReactNode[] = [];
  const footerListElems: React.ReactNode[] = [];

  modalWebsitePages.forEach((site, idx) => {
    const link = (
      <li key={idx} style={{ padding: 0, margin: 0 }}>
        <Link
          component={NavLink}
          to={site.path !== null ? site.path : "#"}
          underline="hover"
          sx={{
            color: variables.colors.links,
            "&.active": {
              color: "#000",
              fontWeight: "bold",
            },
          }}
        >
          {toSentenceCase(site.title)}
        </Link>
      </li>
    );

    if (site.location === HEADER_LOCATION) headerListElems.push(link);
    if (site.location === FOOTER_LOCATION) footerListElems.push(link);
  });

  return (
    <div
      style={{
        fontSize: "1rem",
        height: "100%",
        display: "grid",
        flexDirection: "column",
        gridTemplateRows: "auto 1fr auto",
      }}
    >
      <header style={{ width: "80%", margin: "0 auto" }}>
        <AgamaHeader marginBottom="18px" />

        <NavTemplate>{headerListElems}</NavTemplate>
      </header>

      <div
        style={{
          fontFamily: variables.fonts.body,
          borderTop: `1px solid ${variables.colors.borders}`,
          borderBottom: `1px solid ${variables.colors.borders}`,
          padding: "1rem 1.5rem",
          fontSize: variables.fonts.modalWebsiteBodySize,
          lineHeight: "1.5",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {children}
      </div>

      <footer style={{ width: "80%", margin: "0 auto" }}>
        <NavTemplate>{footerListElems}</NavTemplate>
      </footer>
    </div>
  );
};

export default DesktopTemplate;
