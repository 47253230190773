import React, { useEffect, useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";

// components
import AgamaApp from "../components/AgamaApp";
import { ImageUtils } from "../utils";

const Home: React.FC = () => {
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    ImageUtils.preloadImages(() => {
      setIsloading(false);
    });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClockLoader color="#888" loading={isLoading} size={50} />
        </div>
      ) : (
        <AgamaApp />
      )}
    </div>
  );
};

export default Home;
