import React from "react";
import { IoClose } from "react-icons/io5";
import ClipLoader from "react-spinners/ClipLoader";

// mui
import { Stack, IconButton, Button, TextField } from "@mui/material";

interface FormProps {
  toggleForm?: () => void;
  comment: () => void;
  handleChange: (e: React.ChangeEvent) => void;
  value: string;
  loading?: boolean;
}

const CommentForm: React.FC<FormProps> = ({
  toggleForm,
  comment,
  handleChange,
  value,
  loading,
}) => {
  return (
    // TODO: All border colors should be in the variables file
    <Stack
      sx={{
        border: "1px solid #ddd",
        borderRadius: "10px",
        py: "5px",
        px: "10px",
        mb: "5px",
      }}
    >
      <Stack alignItems="flex-end">
        <IconButton
          onClick={toggleForm}
          sx={{ p: 0, "&:hover": { background: "transparent" } }}
        >
          <IoClose size="20px" color="black" />
        </IconButton>
      </Stack>
      <Stack spacing={2}>
        <TextField
          type="text"
          variant="standard"
          onChange={handleChange}
          value={value}
        />

        <Stack alignItems="center" sx={{ pb: "5px" }}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              borderRadius: "20px",
              textTransform: "capitalize",
            }}
            disabled={value === ""}
            onClick={comment}
            size="small"
          >
            {loading ? (
              <ClipLoader color="#fff" size={20} />
            ) : (
              "Share Your Thoughts"
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CommentForm;
