import React from "react";

// mui
import { Typography, Snackbar, Alert, AlertColor } from "@mui/material";

interface AlertProps {
  onClose: () => void;
  severity: AlertColor;
  message: string;
}

const CustomAlert: React.FC<AlertProps> = ({ onClose, message, severity }) => {
  return (
    <Snackbar
      open={Boolean(message)}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      autoHideDuration={2000}
      onClose={onClose}
      sx={{
        zIndex: 3000,
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        <Typography>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
