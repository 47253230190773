interface Image {
  id: string;
  path: string;
  alt: string;
}

const images: Image[] = [
  {
    id: "agama_logo",
    path: "https://storage.googleapis.com/agama-africana-images-app/agama_logo.png",
    alt: "The Agama Africana logo",
  },
  {
    id: "light_mode_avatar",
    path: "https://storage.googleapis.com/agama-africana-images-app/black_screen.png",
    alt: "Light mode default avatar thumbnail",
  },
  {
    id: "center_button",
    path: "https://storage.googleapis.com/agama-africana-images-app/center_button.png",
    alt: "Center button",
  },
  {
    id: "crosshairs",
    path: "https://storage.googleapis.com/agama-africana-images-app/current_location_icon.png",
    alt: "Crosshairs",
  },
  {
    id: "dark_mode_avatar",
    path: "https://storage.googleapis.com/agama-africana-images-app/offwhite_screen.png",
    alt: "Dark mode default avatar thumbnail",
  },
];

export default images;
