import React from "react";

// mui
import { Stack } from "@mui/material";

// components
import Heading from "./Heading";
import BackButton from "../Drawer/BackButton";

const Template: React.FC<{ to?: string }> = ({ children, to }) => {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: "100%",
      }}
    >
      <Stack>
        <Heading />

        {children}
      </Stack>

      <Stack>
        <BackButton to={to} />
      </Stack>
    </Stack>
  );
};

export default Template;
