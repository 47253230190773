import React from "react";
import { Comment } from "../../api/points";
import { format } from "timeago.js";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import variables from "../../shared/variables";
import { getUserId } from "../../shared/getUserId";

// mui
import { Stack, Typography, IconButton } from "@mui/material";

interface CommentItemProps {
  comment: Comment;
  handleLikeComment: (id: number) => void;
}

const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  handleLikeComment,
}) => {
  const uid = getUserId();

  return (
    <Stack
      className="comment-item"
      key={comment.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        borderBottom: "1px solid #bbb",
        py: "10px",
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        <Typography>{comment.text}</Typography>

        <Stack alignItems="flex-end">
          <Typography
            sx={{ fontSize: "12px", color: `${variables.colors.filterGray}` }}
          >
            @{comment.username}, {format(comment?.time)}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography>{comment.likes} </Typography>
        <IconButton
          sx={{ padding: 0 }}
          disableRipple
          onClick={() => handleLikeComment(comment.id)}
        >
          {comment?.likesArray?.includes(uid) ? (
            <IoHeart size="20px" color="black" />
          ) : (
            <IoHeartOutline size="20px" />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CommentItem;
