import Login from "./Login";

import Unauthorized from "./Unauthorized";
import useUserRoles from "../../utils/useUserRoles";
import { selectUser } from "../../redux/user/userSlice";
import { useAppSelector } from "../../redux/hooks";

import { useNavigate, useParams, useLocation } from "react-router-dom";

import IndexAmbassadors from "./Index";
import ShowAmbassador from "./Show";

import { studentAmbassadors } from "./ambassadorList";

import PostsPerDay from "./PostsPerDay";

export default function Dashboard() {
  const user = useAppSelector(selectUser);
  const roles = useUserRoles(user);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { ambassadorId } = useParams();

  if (ambassadorId) {
    return <ShowAmbassador userId={ambassadorId} />;
  }

  // should be available to admins only. And all pathnames should pass through here. Also, should be a part of the `if (user)` clause
  switch (pathname) {
    case "/dashboard/posts-per-day":
      return <PostsPerDay />;
    default:
      break;
  }

  if (user) {
    // index and individual here
    if (roles?.dashboard?.includes("index")) {
      // render list of all users
      return <IndexAmbassadors ambassadorUsers={studentAmbassadors} />;
    } else if (roles?.dashboard?.includes("show")) {
      navigate(`/dashboard/${user.id}`);
      return null;
    }

    return <Unauthorized />;
  } else {
    return <Login />;
  }
}
