import React from "react";
import { FiXCircle } from "react-icons/fi";
import ReactPlayer from "react-player/lazy";

// mui
import { IconButton, Box } from "@material-ui/core";

interface VideoPreviewProps {
  videoFile: File;
  removeVideo: () => void;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({
  videoFile,
  removeVideo,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
      }}
    >
      <Box
        sx={{
          height: {
            position: "relative",
            xs: "auto",
            sm: "230px",
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bgcolor: "white",
            p: 0,
            "&:hover": {
              bgcolor: "white",
            },
            zIndex: 3000,
          }}
          onClick={removeVideo}
        >
          <FiXCircle color="black" />
        </IconButton>

        <ReactPlayer
          url={URL.createObjectURL(videoFile)}
          width="100%"
          height="100%"
          controls
          light
          style={{
            backgroundColor: "black",
          }}
        />
      </Box>
    </Box>
  );
};

export default VideoPreview;
