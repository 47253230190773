// /** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, { useEffect, useState } from "react";
import variables from "../../shared/variables";

import { createMedia } from "@artsy/fresnel";

import images from "../../shared/images";

import { Tooltip } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";

import { useLocation, useNavigate } from "react-router-dom";
import { useWebsiteModalVisibleFlag } from "../../utils";

import Blog from "./pages/Blog";
import Home from "./pages/Home";
import ChangeRoom from "../../shared/forms/ChangeRoom";
import SuggestAFeature from "../../shared/forms/SuggestAFeature";
import AboutUs from "./pages/AboutUs";
import Help from "./pages/Help";
import Policies from "./pages/Policies";
import TermsOfService from "./pages/TermsOfService";

import DesktopTemplate from "./templates/DesktopTemplate";
import MobileTemplate from "./templates/MobileTemplate";

import "./styles/ModalWebsite.css";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    mobile: 0,
    desktop: 480,
  },
});

const HEADER_LOCATION = "HEADER" as const;
const FOOTER_LOCATION = "FOOTER" as const;

interface Page {
  title: string;
  path: string | null;
  component: React.FunctionComponent;
  location: typeof HEADER_LOCATION | typeof FOOTER_LOCATION;
}

const style = {
  position: "absolute" as const,
  width: `calc(100% - ${2 * 8 * 2}px)`, // TODO: magic numbers
  maxWidth: "600px", // TODO: magic numbers
  height: "calc(100vh - 150px)", // TODO: magic numbers
  m: 2,
  bgcolor: "background.paper",
  borderRadius: "20px 20px 20px 20px",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const logoButtonStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  border: "none",
  position: "absolute" as const,
  top: variables.sizes.cornerButtonMargin,
  left: variables.sizes.cornerButtonMargin,
  zIndex: variables.numbers.overModalZIndex,
};

const logoImageStyle: React.CSSProperties = {
  width: variables.sizes.roundButtonDiameter,
  height: variables.sizes.roundButtonDiameter,
};

const HOMEPAGE_PATH = "/";

const modalWebsitePages: Page[] = [
  { title: "home", path: "/", component: Home, location: HEADER_LOCATION },
  /*
  {
    title: "change room",
    path: "/change-room",
    component: ChangeRoom,
    location: HEADER_LOCATION,
  },
  {
    title: "suggest a feature",
    path: "/suggest-a-feature",
    component: SuggestAFeature,
    location: HEADER_LOCATION,
  },
  */
  {
    title: "about us",
    path: "/about-us",
    component: AboutUs,
    location: HEADER_LOCATION,
  },
  { title: "help", path: "/help", component: Help, location: HEADER_LOCATION },
  {
    title: "policies",
    path: "/policies",
    component: Policies,
    location: FOOTER_LOCATION,
  },
  {
    title: "terms of service",
    path: "/terms-of-service",
    component: TermsOfService,
    location: FOOTER_LOCATION,
  },
  { title: "blog", path: "/blog", component: Blog, location: FOOTER_LOCATION },
];

const ModalWebsite: React.FC<{
  onOpenPanel: () => void;
  onClosePanel: () => void;
}> = ({ onOpenPanel, onClosePanel }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useWebsiteModalVisibleFlag(
    location.pathname
  );

  const isHomepage = location.pathname === HOMEPAGE_PATH;

  const [open, setOpen] = useState(isModalOpen);

  const page = modalWebsitePages.find(
    (pageObj) => location.pathname === pageObj.path
  );
  const Component = page?.component;
  const contents = Component ? <Component /> : null;

  // Todo: move fast and break things; DRY this up later
  const mobileButtonCSS = open
    ? css`
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -0.25rem;
          bottom: 0;
          left: 0.48rem;
          right: 0;
          border-radius: 8888px;
          border: 2px dotted #fff;
          width: 3.1rem;
          height: 3.1rem;
          animation: spin 30s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      `
    : undefined;

  const desktopButtonCSS = open
    ? css`
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -0.2rem;
          bottom: 0;
          left: 0.2rem;
          right: 0;
          border-radius: 8888px;
          border: 2px dotted #fff;
          width: 3.1rem;
          height: 3.1rem;
          animation: spin 30s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      `
    : undefined;

  const handleToggle = () => {
    navigate("/");
    if (open === false) {
      onOpenPanel();
    } else {
      onClosePanel();
    }

    setOpen((openState: boolean) => {
      if (isHomepage) {
        setIsModalOpen(!openState);
      }

      return !openState;
    });
  };

  const logoImgPath =
    images.find((image) => image.id === "agama_logo")?.path || "";

  return (
    <div>
      <MediaContextProvider>
        {/* TODO: Something less brittle */}
        <Media at="mobile">
          <style>
            {`   
              .MuiModal-root .MuiBox-root{
                position: relative !important;
                top: 1.7rem !important;
              }
            `}
          </style>
          <Tooltip title="Click to toggle Agama Africana information modal">
            <button
              css={mobileButtonCSS}
              onClick={handleToggle}
              style={logoButtonStyle}
            >
              <img
                src={logoImgPath}
                style={logoImageStyle}
                alt="Agama Africana logo"
              />
            </button>
          </Tooltip>
        </Media>
        <Media greaterThanOrEqual="desktop">
          <Tooltip title="Click to toggle Agama Africana information modal">
            <button
              css={desktopButtonCSS}
              onClick={handleToggle}
              style={logoButtonStyle}
            >
              <img
                src={logoImgPath}
                style={logoImageStyle}
                alt="Agama Africana logo"
              />
            </button>
          </Tooltip>
        </Media>
      </MediaContextProvider>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleToggle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: 0,
        }}
      >
        <Grow in={open}>
          <Box sx={style}>
            <MediaContextProvider>
              <Media at="mobile">
                <style>
                  {`   
                    .fresnel-at-mobile {
                      height: 100%; 
                    }
                  `}
                </style>
                <MobileTemplate>{contents}</MobileTemplate>
              </Media>
              <Media greaterThanOrEqual="desktop">
                <style>
                  {`   
                    .fresnel-greaterThanOrEqual-desktop {
                      height: 100%;
                    }
                  `}
                </style>
                <DesktopTemplate>{contents}</DesktopTemplate>
              </Media>
            </MediaContextProvider>
          </Box>
        </Grow>
      </Modal>
    </div>
  );
};

export {
  modalWebsitePages,
  HEADER_LOCATION,
  FOOTER_LOCATION,
  ModalWebsite as default,
};

/*

* Margins
* Border
* Marching ants
* Deactivate hamburger
* Centralize dashboard state
  * should hide both drawer and menu when clicked
*
*/
