import React from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

interface DropDownProps {
  options: string[];
  onSelect: (arg0: string) => void;
  variant?: "standard" | "outlined" | "filled" | undefined;
  size?: "small" | "medium" | undefined;
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  onSelect,
  children,
  variant,
  size,
}) => {
  variant = variant || "standard";
  size = size || "medium";
  const menuItems = options.map((option, i) => (
    <MenuItem key={i} value={option}>
      {option}
    </MenuItem>
  ));
  return (
    <FormControl size="medium" variant={variant} sx={{ ml: 1, mr: 0.1 }}>
      <Select
        size={size}
        onChange={(e) => onSelect(e.target.value as string)}
        value={children}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default DropDown;
