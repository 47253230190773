import React from "react";
import { Message, Point, getMsgs } from "../../api/points";
import variables from "../../shared/variables";

// components
import MessageListItem from "../../shared/MessageListItem";

const QuestionContent: React.FC<{
  points: Point[];
  onClick?: (msg: Message, pt: Point) => void;
}> = ({ points, onClick }) => {
  const msgs = getMsgs(points);

  const msgObjs = points
    .map((point) => point.messages.map((msg) => ({ msg, point })))
    .flat();

  const noQuestionsContent = (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 1rem",
      }}
    >
      <h3 style={{ fontFamily: variables.fonts.body }}>
        No questions about this location have been asked
      </h3>
    </div>
  );

  const questionsContent = (
    <ul style={{ padding: 0, margin: 0 }}>
      {msgObjs.map((msg, i) => (
        <MessageListItem
          key={i}
          message={msg.msg}
          showLocation={true}
          onClick={() => {
            onClick && onClick(msg.msg, msg.point);
          }}
        />
      ))}
    </ul>
  );

  return msgs.length ? questionsContent : noQuestionsContent;
};

export default QuestionContent;
