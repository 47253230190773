// include Cryptr from "cryptr";
// new referral system? Like Jay-Z said, interior decorate the watch
import Cryptr from "cryptr";

// TODO: remove Cryptr

// const ENCRYPTION_KEY = "FWGXaWkv0i1YXEKfdBjuqAqrBziBmwOIcdXoUPi6AfE";
// const cryptr = new Cryptr(ENCRYPTION_KEY);

const encrypt = (input: string): string => {
  return input
    .split("")
    .map((ch) => ch.charCodeAt(0))
    .join(".");
};

const decrypt = (cypher: string): string => {
  return cypher
    .split(".")
    .map((num) => String.fromCharCode(+num))
    .join("");
};

export const encryptReferralCode = (referrerName: string, source = "") => {
  return encodeURIComponent(encrypt(`_${referrerName}|${source}`));
};

export const decryptReferralCode = (
  referralCode = ""
): [string, string] | null => {
  let decryptedString;

  try {
    decryptedString = decrypt(decodeURIComponent(referralCode));
  } catch (err) {
    decryptedString = "";
  }

  if (decryptedString[0] !== "_") {
    return null;
  }

  return decryptedString.substring(1).split("|") as [string, string];
};
