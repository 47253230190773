import { Typography } from "@material-ui/core";
import variables from "./variables";

interface AgamaHeaderProps {
  marginTop?: string;
  marginBottom?: string;
}

const AgamaHeader = ({
  marginTop = "0",
  marginBottom = "0",
}: AgamaHeaderProps) => (
  <Typography
    variant="h6"
    sx={{
      color: variables.colors.header,
      fontSize: "1.5rem",
      fontFamily: variables.fonts.logo,
      fontWeight: 700,
      textTransform: "uppercase",
      mt: marginTop,
      mb: marginBottom,
      textAlign: "center",
    }}
  >
    Agama Africana
  </Typography>
);

export default AgamaHeader;
