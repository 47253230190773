// /** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ReactPlayer from "react-player/lazy";

const videoLocation =
  "https://agama-africana.s3.amazonaws.com/agama-demo_M65fxtTn.mp4";

const Home = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Home</h1> */}
      <p>
        Welcome to Agama Africana. Let&apos;s talk about what&apos;s happening
        where you are.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "38vh" }}>
          <ReactPlayer
            url={videoLocation}
            controls
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <br />
      <details>
        <summary>
          <h3 style={{ display: "inline-block" }}>Using the App</h3>
        </summary>
        <p>
          <span
            css={css`
              animation: blinker 1.5s linear 6;

              @keyframes blinker {
                50% {
                  opacity: 0.1;
                }
              }
            `}
          >
            Click &ldquo;Allow&rdquo; if your browser asks to know your
            location.
          </span>
          <span> This will allow you to make posts on the app.</span>
        </p>
        <p>
          Next, close this window by clicking on the logo, and then open up the
          location feed by clicking on &ldquo;Browse&rdquo;. No need to create
          an account to view information, but you will need one to create posts.
        </p>
      </details>

      <br />
      <details>
        <summary>
          <h3 style={{ display: "inline-block" }}>Registering</h3>
        </summary>
        <p>
          Register or log in by opening the hamburger menu, and clicking the
          register/login link.
        </p>
      </details>

      <br />
      <details>
        <summary>
          <h3 style={{ display: "inline-block" }}>Posting questions</h3>
        </summary>
        <p>
          To ask a question, you&apos;ll need to log in or register first. After
          that, click the &ldquo;Ask&rdquo; button, fill the form and then click
          &ldquo;Ask&rdquo;. Then, another user can come by your question on the
          map and answer it for you.
        </p>
      </details>

      <br />

      <details>
        <summary>
          <h3 style={{ display: "inline-block" }}>Posting news</h3>
        </summary>
        <p>
          To make a post, log in or register, then click the
          &ldquo;Announce&rdquo; button. Fill the form and let them know!
        </p>
      </details>

      <br />
      <details>
        <summary>
          <h3 style={{ display: "inline-block" }}>Pizza Party!</h3>
        </summary>
        <p>
          We are having a pizza party on Sun 27 Mar at 2 PM! Browse the app to
          find out where. If you are stuck you can call us at 024 565 1674.
        </p>
      </details>
    </div>
  );
};

export default Home;
