import LoginRegister from "../../components/LoginRegister/LoginRegister";
import { Typography } from "@material-ui/core";

export default function Login() {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", fontWeight: 600, m: 5 }}
      >
        Login In
      </Typography>
      <LoginRegister
        componentLocation="Dashboard"
        successPageLogin="/dashboard"
      />
    </div>
  );
}
