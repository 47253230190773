const DashboardTemplate: React.FC = ({ children }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "3rem" }}
    >
      <div
        style={{
          borderRadius: "3rem",
          padding: "3rem 4rem",
          maxWidth: "650px",
          width: "100%",
          border: "1px solid #ddd",
          margin: "1rem",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardTemplate;
