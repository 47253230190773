import React, { useState, useEffect } from "react";
import { Point } from "../../api/points";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectIsThinking } from "../../redux/points/pointsSlice";

// components
import Spinner from "../../shared/Spinner";
import QuestionContent from "./QuestionContent";

const BrowseTab: React.FC<{ visiblePoints: Point[] }> = ({ visiblePoints }) => {
  const isThinking = useAppSelector(selectIsThinking);

  const [contents, setContents] = useState(<></>);

  useEffect(() => {
    if (isThinking) {
      setContents(<Spinner></Spinner>);
    } else {
      setContents(<QuestionContent points={visiblePoints} />);
    }
  }, [isThinking, visiblePoints]);

  return <div style={{ width: "100%", height: "100%" }}>{contents}</div>;
};

export default BrowseTab;
