/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import TextField from "@material-ui/core/TextField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

import variables from "../shared/variables";
import Button from "../shared/Button";
import { Coordinate } from "../shared/types";

import {
  Location,
  findPosts,
  findLocations,
  PostSearchResult,
} from "../api/points";

// redux
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentView } from "../redux/points/pointsSlice";
import { selectUser } from "../redux/user/userSlice";

import MessageListItem from "../shared/MessageListItem";

import UnstyledButton from "../shared/UnstyledButton";
import { trackEvent } from "../analytics/SegmentAnalytics";

interface SearchPanelParams {
  onSelectLocation?: () => void;
  onSelectPost?: () => void;
  onClickResult: ({ x, y }: Coordinate) => void;
}

const H2 = styled.h2`
  font-size: 1.15rem;
  font-family: helvetica;
`;

const Header: React.FC<{
  onSetSelected: (arg0: string) => any;
  selectedTab: string;
}> = ({ selectedTab, onSetSelected }) => {
  const [selected, setSelected] = useState(selectedTab);

  const onClickButton = (buttonName: string) => {
    setSelected(buttonName);
    onSetSelected(buttonName);
  };

  useEffect(() => {
    setSelected(selectedTab);
  }, [selectedTab]);

  return (
    <ul
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: 0 1rem 0.7rem 1rem;

        li {
          text-decoration: none;
          list-style-type: none;
        }

        button {
          position: relative;
        }

        button:before {
          content: "";
          background-color: black;
          height: 3px;
          width: 0;
          position: absolute;
          bottom: -0.3rem;
          left: 0;
          transition: all 0.4s;
          border-radius: 5px;
        }

        button.selected:before {
          width: 100%;
        }
      `}
    >
      <li>
        <UnstyledButton
          style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
          onClick={() => onClickButton("location")}
          className={selected === "location" ? "selected" : ""}
        >
          &nbsp;Locations&nbsp;
        </UnstyledButton>
      </li>
      <li>
        <UnstyledButton
          style={{ fontFamily: "monospace", fontSize: "0.9rem" }}
          onClick={() => onClickButton("post")}
          className={selected === "post" ? "selected" : ""}
        >
          &nbsp;Posts&nbsp;
        </UnstyledButton>
      </li>
    </ul>
  );
};

const SearchPanel: React.FC<SearchPanelParams> = ({
  onSelectLocation,
  onSelectPost,
  onClickResult,
}) => {
  // const [location, setLocation] = useState(locations[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [locationResults, setLocationResults] = useState<
    JSX.Element | JSX.Element[] | null
  >(<></>);
  const [postResults, setPostResults] = useState<
    JSX.Element | JSX.Element[] | null
  >(<></>);
  const [selectedTab, setSelectedTab] = useState("location");
  const [searchResultsContents, setSearchResultsContents] = useState<
    JSX.Element | JSX.Element[] | null
  >(<></>);

  // redux
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const processAndSetPostResults = (searchResults: PostSearchResult[]) => {
    const postResultsDisplay = searchResults
      .map((post) =>
        post.msgs
          .map((msg, idx) => (
            <MessageListItem
              // #TODO: need to use msg.id as key, not idx
              key={idx}
              message={msg}
              showLocation={true}
              onClick={(e) => {
                onClickResult({ x: post.point.x, y: post.point.y });
              }}
            />
          ))
          .flat()
      )
      .flat();

    if (searchResults.length) {
      setPostResults(postResultsDisplay);
    } else {
      setPostResults(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "calc(100% - 6rem)",
          }}
        >
          <H2>No posts found.</H2>
        </div>
      );
    }
  };

  const processAndSetLocationResults = (locations: Location[]) => {
    if (locations.length) {
      setLocationResults(
        <ul>
          {locations.map((location, idx) => (
            <li key={idx}>
              <UnstyledButton
                onClick={(e) => {
                  onClickResult(location.position);
                }}
                style={{
                  fontFamily: variables.fonts.body,
                  fontSize: "0.9rem",
                  color: "black",
                }}
              >
                {location.name}
              </UnstyledButton>
            </li>
          ))}
        </ul>
      );
    } else {
      setLocationResults(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "calc(100% - 6rem)",
          }}
        >
          <H2>No locations found.</H2>
        </div>
      );
    }
  };

  const onSubmitSearchTerm = () => {
    trackEvent("User searches for location or post", {
      username: user?.username,
      email: user?.email,
    });
    setHasSearched(true);

    processAndSetPostResults(findPosts(searchTerm));
    processAndSetLocationResults(findLocations(searchTerm));
  };

  const renderStageOneSearchTerm = () => {
    return (
      !hasSearched && (
        <div>
          <div style={{ marginTop: "3rem", padding: "0 1.3rem" }}>
            <TextField
              id="post-textarea"
              variant="standard"
              placeholder="Search for?"
              value={searchTerm}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmitSearchTerm();
                }
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
              multiline
              fullWidth
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1rem 0 1.5rem 0",
            }}
          >
            <Button onClick={onSubmitSearchTerm}>Search</Button>
          </div>
        </div>
      )
    );
  };

  const renderStageTwoResults = () => {
    if (!hasSearched) {
      return null;
    }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <button
            onClick={() => {
              setSearchTerm("");
              setHasSearched(false);
              setPostResults(null);
              setLocationResults(null);
              setSelectedTab("location");
            }}
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: 0,
              margin: 0,
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0.7rem 0.4rem", fontSize: "1.2rem" }}
              icon={faArrowCircleLeft}
            />
          </button>
        </div>
        <div>
          <Header
            selectedTab={selectedTab}
            onSetSelected={(tab) => setSelectedTab(tab)}
          />
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          {searchResultsContents}
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setCurrentView("Search"));
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case "location":
        setSearchResultsContents(locationResults);
        break;
      case "post":
        setSearchResultsContents(postResults);
        break;
    }
  }, [selectedTab, locationResults, postResults]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {renderStageOneSearchTerm()}
      {renderStageTwoResults()}
    </div>
  );
};

export default SearchPanel;
