import variables from "../shared/variables";
import styled from "@emotion/styled";

import { motion } from "framer-motion";

// TODO: Replace with Typography

export const BodyParagraph = styled.p`
  font-family: ${variables.fonts.body};
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const Card = styled(motion.div)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SideCard = styled(motion.div)`
  position: absolute;
  top: 0,
  right: 0,
  width: 0;
  height: 100%;
`;
