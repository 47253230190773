const Policies = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Policies</h1> */}
      <p>
        We do not have a lawyer on the team (yet) but all we ask for now is
        simple: just be polite and patient with your fellow users and you will
        help in ensuring that all of us have a great time here.
      </p>
    </div>
  );
};

export default Policies;
