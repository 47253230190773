/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import { allActualUsers } from "../../firebase/user";
import { User } from "../../redux/user/userSlice";
import DashboardTemplate from "./DashboardTemplate";
import { getPosts } from "./ambassadorData";

export default function PostsPerDay() {
  const [actualUsers, setActualUsers] = useState<User[]>();
  const [postsHash, setPostsHash] = useState<Record<string, unknown>>();
  const [tableRows, setTableRows] = useState<React.ReactNode>();

  useEffect(() => {
    (async () => {
      setActualUsers(await allActualUsers());
      console.log("we have setActualUsers");
    })();
  }, []);

  useEffect(() => {
    const postHashTmp: Record<string, unknown> = {};

    if (actualUsers) {
      console.log("actualUsers");
      console.log(actualUsers);

      (async () => {
        const posts = await getPosts(actualUsers.map((user) => user.id));

        posts.forEach((post) => {
          const date = new Date(post.time).toLocaleDateString();
          const dateAsTimestamp = +new Date(date);

          if (!postHashTmp[dateAsTimestamp]) {
            postHashTmp[dateAsTimestamp] = {
              date,
              users: [],
            };
          }

          (postHashTmp[dateAsTimestamp] as { users: string[] }).users.push(
            post.username
          );
        });
        console.log("postHashTmp");
        console.log(postHashTmp);

        setPostsHash(postHashTmp);
      })();
    }
  }, [setActualUsers]);

  useEffect(() => {
    if (postsHash) {
      console.log("postsHash");
      console.log(postsHash);

      const entries = Object.entries(postsHash as any);
      const sortedEntries = entries.sort(([aDate], [bDate]) => +aDate - +bDate);

      setTableRows(
        sortedEntries.map(([date, data]) => {
          console.log("data");
          console.log(data);
          return (
            <tr key={date}>
              <td>{(data as any).date}</td>
              <td>{(data as any).users.length}</td>
            </tr>
          );
        })
      );
    }
  }, [postsHash]);

  return (
    <DashboardTemplate>
      <div>
        <h1 style={{ fontFamily: "Helvetica", textAlign: "center" }}>
          Posts per Day
        </h1>
        <table>
          <tr>
            <th>Day</th>
            <th>Posts</th>
          </tr>
          {tableRows}
        </table>
      </div>
    </DashboardTemplate>
  );
}
