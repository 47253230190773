import React, { useState } from "react";
import { useNavigate, NavLink, useLocation, Navigate } from "react-router-dom";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// firebase
import {
  login,
  loginWithGoogle,
  loginWithFacebook,
  register,
} from "../../firebase/auth";

// mui
import { Stack, Typography, Link } from "@mui/material";

// components
import CustomButton from "../CustomButton/CustomButton";
import TextInput from "../TextInput/TextInput";
import BackButton from "../Drawer/BackButton";

import { trackEvent } from "../../analytics/SegmentAnalytics";

import CustomAlert from "../CustomAlert/CustomAlert";
import { getUserId } from "../../shared/getUserId";

const Login: React.FC<{
  componentLocation?: string;
  successPageLogin?: string;
}> = ({ componentLocation = "Drawer", successPageLogin = "/" }) => {
  const user = useAppSelector(selectUser);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const isRegister = pathname === "/register";

  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const signInDisabled = email === "" || password === "";
  const signUpDisabled =
    username === "" ||
    email === "" ||
    password === "" ||
    confirmPassword === "";

  const handleAuthentication = async (
    event: React.FormEvent,
    successPageAuthentication = "/"
  ) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (isRegister) {
        if (password !== confirmPassword) {
          setErrorMessage("Passwords do not match");
          setLoading(false);
          return;
        }

        if (username.includes(" ")) {
          setErrorMessage("Username can not have empty spaces");
          setLoading(false);
          return;
        }
        await register({
          username,
          email,
          password,
        });
      } else {
        trackEvent("User login", {
          email: email,
        });
        await login({
          email,
          password,
        });
      }

      navigate(successPageAuthentication, { replace: true });
    } catch (error) {
      setLoading(false);

      if (!(error instanceof Error)) {
        return;
      }

      console.log(error.message);

      if (error.message.includes("invalid-email")) {
        setErrorMessage("Invalid email address");
      }

      if (error.message.includes("user-not-found")) {
        setErrorMessage("User does not exist");
      }

      if (error.message.includes("wrong-password")) {
        setErrorMessage("Password is incorrect");
      }

      if (error.message.includes("weak-password")) {
        setErrorMessage("Password should be at least 6 characters");
      }

      if (error.message.includes("email-already-in-use")) {
        setErrorMessage("Email address already in use");
      }

      if (error.message.includes("username-taken")) {
        setErrorMessage("Username is not available. Try another");
      }

      if (error.message.includes("network-request-failed")) {
        setErrorMessage("No internet. Check your connection");
      }
    }
  };

  const handleGoogleLogin = async (successPageGoogle = "/") => {
    trackEvent("User login with Google", { uid: getUserId() });
    setGoogleLoading(true);
    try {
      await loginWithGoogle();
      navigate(successPageGoogle, { replace: true });
    } catch (error) {
      setGoogleLoading(false);

      if (!(error instanceof Error)) {
        return;
      }

      console.log(error.message);
    }
  };

  const handleFacebookLogin = async (successPageFacebook = "/") => {
    trackEvent("User login with Facebook", { uid: getUserId() });
    setFacebookLoading(true);
    try {
      await loginWithFacebook();
      navigate(successPageFacebook, { replace: true });
    } catch (error) {
      setFacebookLoading(false);

      if (!(error instanceof Error)) {
        return;
      }

      console.log(error.message);
    }
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  if (user) {
    return <Navigate to={successPageLogin} />;
  }

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={5}>
        <Stack spacing={2}>
          {isRegister && (
            <TextInput
              type="text"
              label="Username"
              onChange={(event: React.ChangeEvent) => {
                const target = event.target as HTMLInputElement;
                setUsername(target.value);
              }}
              value={username}
            />
          )}

          <TextInput
            type="email"
            label="E-Mail"
            onChange={(event: React.ChangeEvent) => {
              const target = event.target as HTMLInputElement;
              setEmail(target.value);
            }}
            value={email}
          />

          <TextInput
            type="password"
            label="Password"
            onChange={(event: React.ChangeEvent) => {
              const target = event.target as HTMLInputElement;
              setPassword(target.value);
            }}
            value={password}
          />

          {isRegister && (
            <TextInput
              type="password"
              label="Confirm Password"
              onChange={(event: React.ChangeEvent) => {
                const target = event.target as HTMLInputElement;
                setConfirmPassword(target.value);
              }}
              value={confirmPassword}
            />
          )}
        </Stack>

        <Stack spacing={2} alignItems="center">
          <CustomButton
            loading={loading}
            text={isRegister ? "Sign Up" : "Sign In"}
            onClick={(event) => handleAuthentication(event, successPageLogin)}
            isDisabled={isRegister ? signUpDisabled : signInDisabled}
          />

          <CustomButton
            loading={googleLoading}
            text={!isRegister ? "Sign In With Google" : "Sign Up With Google"}
            type="google"
            onClick={(event) => handleGoogleLogin(successPageLogin)}
          />

          <CustomButton
            loading={facebookLoading}
            text={
              !isRegister ? "Sign In With Facebook" : "Sign Up With Facebook"
            }
            type="facebook"
            onClick={(event) => handleFacebookLogin(successPageLogin)}
          />
        </Stack>
        <Stack alignItems="center" spacing={1}>
          {!isRegister && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2">Forgot Password?</Typography>

              <Link component={NavLink} to="/reset-password">
                <Typography variant="body2">Reset</Typography>
              </Link>
            </Stack>
          )}
          {componentLocation === "Drawer" ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2">
                {isRegister
                  ? "Already have an account"
                  : "Don't have an account?"}
              </Typography>

              <Link
                data-testid="signIn/login link"
                component={NavLink}
                to={isRegister ? "/login" : "/register"}
              >
                <Typography variant="body2">
                  {isRegister ? "Sign In" : "Sign Up"}
                </Typography>
              </Link>
            </Stack>
          ) : null}
        </Stack>
      </Stack>

      <Stack direction="column" alignItems="center" spacing={1}>
        {componentLocation === "Drawer" ? <BackButton to="/" /> : null}

        <CustomAlert
          onClose={handleCloseErrorAlert}
          severity="error"
          message={errorMessage}
        />
      </Stack>
    </Stack>
  );
};

export default Login;
