import { get, set } from "../utils/localstorage_utils";

// it generates a random alphanumeric string
function randomUserID() {
  return (
    Math.random().toString(16).substring(2, 10) +
    Math.random().toString(16).substring(2, 10)
  );
}

export const getUserId = () => get("userId");

export function setUserId(userStateId: string | undefined) {
  const userId: string = getUserId();
  // checks if user has a userId if not assigns on from randomUserID()
  if (userId.length > 0) {
    if (userStateId && userStateId?.length > 0) {
      set("userId", userStateId);
    }
  } else {
    // checks if user if logged in. if yes, it replaces random user Id with email
    set("userId", randomUserID());
  }
}
