import variables from "../../shared/variables";

interface ControlsHeaderProps {
  selectedTab: string;
  onClick: React.MouseEventHandler;
}

export default function ControlsHeader({
  selectedTab,
  onClick,
}: ControlsHeaderProps) {
  return (
    <div
      style={{
        padding: "0.3rem 0.5rem 0.3rem 1rem",
        fontSize: "0.8rem",
        position: "relative",
        height: "1.65rem",
        bottom: "2.3rem",
        borderRadius: "20px",
        backgroundColor: "black",
        color: "white",
        zIndex: 10,
      }}
    >
      <span
        style={{
          fontFamily: variables.fonts.body,
          fontWeight: 700,
          marginRight: "1rem",
          bottom: "0.07rem",
          position: "relative",
        }}
      >
        {selectedTab}
      </span>
      <button
        style={{
          height: "1rem",
          width: "1rem",
          backgroundColor: "white",
          borderRadius: "100%",
          border: "1px solid white",
        }}
        onClick={onClick}
      >
        <span
          style={{
            color: "black",
            fontWeight: 700,
            fontSize: "0.6rem",
            right: "0.12rem",
            bottom: "0.15rem",
            position: "relative",
          }}
        >
          X
        </span>
      </button>
    </div>
  );
}
