import React from "react";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// mui
import { Stack, Typography } from "@material-ui/core";

// custom component
import DrawerMenuLink from "../Drawer/DrawerMenuLink";
import BackButton from "../Drawer/BackButton";
import ProfileAvatar from "../Drawer/ProfileAvatar";

const UserProfile: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <Stack justifyContent="space-between" sx={{ px: 5, height: "100%" }}>
      <Stack spacing={3}>
        <Stack alignItems="center" spacing={3}>
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              fontWeight: 700,
              color: "text.primary",
            }}
          >
            User Profile
          </Typography>

          <ProfileAvatar />

          <Stack alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{
                textTransform: "capitalize",
                fontWeight: 700,
                color: "text.primary",
              }}
            >
              username
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                textTransform: "lowercase",
                fontWeight: 700,
                color: "text.secondary",
              }}
            >
              @{user?.username}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <DrawerMenuLink to="/" text="Posts" />
        </Stack>
      </Stack>

      <Stack alignItems="center">
        <BackButton />
      </Stack>
    </Stack>
  );
};

export default UserProfile;
