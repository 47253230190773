import { useEffect, useState } from "react";
import { User } from "../../redux/user/userSlice";
import {
  AmbassadorData,
  getAmbassadorData,
  allCutoffTimes,
} from "./ambassadorData";
import { findUser } from "../../firebase/user";

import DashboardTemplate from "./DashboardTemplate";

export default function ShowAmbassador({ userId }: { userId: string }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<AmbassadorData | undefined>();
  const [ambassador, setAmbassador] = useState<User | undefined>();

  useEffect(() => {
    (async () => {
      const user = (await findUser(userId)) as User;

      setAmbassador(user);
    })();
  }, []);

  useEffect(() => {
    if (ambassador) {
      (async () => {
        const data = await getAmbassadorData(ambassador?.id);
        setUserData(data);
        setIsLoading(false);
      })();
    }
  }, [ambassador]);

  //

  return !isLoading ? (
    <DashboardTemplate>
      <div>
        <h1 style={{ fontFamily: "Helvetica", textAlign: "center" }}>
          @{ambassador?.username}&apos;s Dashboard
        </h1>
        <p>
          <em>Email: </em>
          <span>{ambassador?.email}</span>
        </p>
        <br />

        <p>
          <em>Payment #{allCutoffTimes.length} cutoff time: </em>
          <span>{new Date(allCutoffTimes[0]).toLocaleString()}</span>
          <br />
          <em>Number of posts: </em>
          <span>{userData?.posts.length}</span>
        </p>
      </div>
    </DashboardTemplate>
  ) : (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}
