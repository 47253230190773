// TODO: Move this into Controls folder

import React, { useEffect, useState } from "react";
import { math } from "polished";
import variables from "../shared/variables";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faSearch,
  faPlus,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Point } from "../api/points";
import { trackEvent } from "../analytics/SegmentAnalytics";
import { Coordinate } from "../shared/types";

// redux
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setCenterMode,
  setCurrentView,
  selectIsThinking,
} from "../redux/points/pointsSlice";
import { selectUser } from "../redux/user/userSlice";

// components
import { Card } from "../shared/styled_components";
import Spinner from "../shared/Spinner";
import QueryPanel from "./QueryPanel/QueryPanel";
import PostPanel from "./PostPanel/PostPanel";
import BrowsePanel from "./BrowsePanel";
import SearchPanel from "./SearchPanel";
import ControlsHeader from "./ControlMenu/ControlsHeader";

interface ControlProps {
  title: string;
  icon: React.ReactNode;
  onClick: React.MouseEventHandler;
}

const Control: React.FC<ControlProps> = ({ title, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "0.6rem",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          borderRadius: "100%",
          width: "2rem",
          height: "2rem",
        }}
      >
        {icon}
      </div>
      <div>
        <span
          style={{
            fontWeight: 700,
            fontSize: "0.7rem",
            fontFamily: variables.fonts.body,
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

const Icon: React.FC<{ style?: React.CSSProperties; icon: IconProp }> = ({
  icon,
  style = {},
}) => {
  return (
    <span style={{ ...style, position: "relative" }}>
      <FontAwesomeIcon style={{ color: "white" }} icon={icon} />
    </span>
  );
};

const controlCardStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: `${variables.sizes.panelMarginTop} ${variables.sizes.panelMarginSides} 0`,
  bottom: 0,
  height: `${variables.sizes.controlsCardInactiveHeight}${variables.sizes.controlsCardHeightUnit}`,
  backgroundColor: "white",
};

interface ControlsProps {
  onOpenPanel: () => void;
  onClosePanel: () => void;
  onHidePanel: () => void;
  visiblePoints: Point[];
  isUsersLocation: boolean;
  onRequestCentering: (coord: Coordinate) => void;
  currentLocation: Coordinate;
  isMenuHidden: boolean;
}

const PanelContentWrapper: React.FC = ({ children }) => {
  const totalMarginWidth = math(`${variables.sizes.panelMarginSides} * 2`);
  return (
    <div
      className="no-scroll-container"
      style={{
        position: "absolute",
        overflowX: "hidden",
        width: `calc(100% - ${totalMarginWidth})`,
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

const Panel: React.FC = ({ children }) => {
  if (children) {
    return <PanelContentWrapper>{children}</PanelContentWrapper>;
  } else {
    return null;
  }
};

const Controls: React.FC<ControlsProps> = ({
  onOpenPanel,
  onClosePanel,
  onHidePanel,
  visiblePoints,
  isUsersLocation,
  onRequestCentering,
  currentLocation,
  isMenuHidden,
}) => {
  const [openTab, setOpenTab] = useState("");
  const [justLoaded, setJustLoaded] = useState(true);
  const [panelOpened, setPanelOpened] = useState(false);
  const [panelHeightPercent, setPanelHeightPercent] = useState(10);
  const [panelContents, setPanelContents] = useState<
    JSX.Element | undefined | null
  >();

  // redux
  const dispatch = useAppDispatch();
  const isThinking = useAppSelector(selectIsThinking);
  const user = useAppSelector(selectUser);

  const browsePanelContents = <BrowsePanel points={visiblePoints} />;

  const switchToBrowse = () => {
    const newTab = "Browse";
    setOpenTab(newTab);
    setPanelContents(browsePanelContents);
  };

  const determinePanelContents = (openTabStr: string) => {
    let contents = null;

    // Todo: actual locations
    const locations = [
      // "Legon Bypass",
      // "West Legon",
      "Accra",
      "Ghana",
      "West Africa",
      "Africa",
      "the World",
    ];

    switch (openTabStr) {
      case "Browse":
        contents = browsePanelContents;
        break;
      case "Announce":
        contents = (
          <PostPanel
            onCreatePost={() => {
              switchToBrowse();
            }}
            currentLocation={currentLocation}
            locations={locations}
          />
        );
        break;
      case "Query":
        contents = (
          <QueryPanel
            visiblePoints={visiblePoints}
            onCreatePost={() => {
              const newTab = "Browse";
              setOpenTab(newTab);
              setPanelContents(determinePanelContents(newTab));
            }}
          />
        );
        break;
      case "Search":
        contents = (
          <SearchPanel
            onClickResult={({ x, y }: Coordinate) => {
              onRequestCentering({ x, y });
              switchToBrowse();
            }}
          />
        );
        break;
    }

    return contents;
  };

  const openPanel = () => {
    setPanelOpened(true);
    setPanelHeightPercent(variables.sizes.controlsCardActiveHeight);
    onOpenPanel();
  };

  const closePanel = (newControlPanelHeight?: number) => {
    newControlPanelHeight =
      newControlPanelHeight !== undefined
        ? newControlPanelHeight
        : variables.sizes.controlsCardInactiveHeight;

    setPanelOpened(false);
    setPanelHeightPercent(newControlPanelHeight);
    setPanelContents(null);
    dispatch(setCenterMode("fixed"));
    dispatch(setCurrentView("Menu"));
    onClosePanel();
  };

  const togglePanel = (clickedTab = "") => {
    if (justLoaded) {
      setJustLoaded(false);
    }

    if (!panelOpened) {
      openPanel();
    }

    setOpenTab(clickedTab);
    setPanelContents(determinePanelContents(clickedTab));
  };

  const controlsButtons = [
    <Control
      key={1}
      title="Browse"
      icon={<Icon style={{ top: "0.45rem", left: "0.5rem" }} icon={faGlobe} />}
      onClick={() => {
        togglePanel("Browse");
        trackEvent("User selects browse tab", {
          username: user?.username,
          email: user?.email,
        });
      }}
    />,
    <Control
      key={2}
      title="Announce"
      icon={<Icon style={{ top: "0.45rem", left: "0.55rem" }} icon={faPlus} />}
      onClick={() => {
        togglePanel("Announce");
        trackEvent("User selects announce tab", {
          username: user?.username,
          email: user?.email,
        });
      }}
    />,
    <Control
      key={3}
      title="Ask"
      icon={
        <Icon style={{ top: "0.45rem", left: "0.65rem" }} icon={faQuestion} />
      }
      onClick={() => {
        togglePanel("Query");
        trackEvent("User selects query tab", {
          username: user?.username,
          email: user?.email,
        });
      }}
    />,
    <Control
      key={4}
      title="Search"
      icon={
        <Icon style={{ top: "0.45rem", left: "0.45rem" }} icon={faSearch} />
      }
      onClick={() => {
        togglePanel("Search");
        trackEvent("User selects search tab", {
          username: user?.username,
          email: user?.email,
        });
      }}
    />,
  ];

  const controlsButtonsWrapper = () => {
    const padding = `0 ${variables.sizes.phonePadding}${variables.sizes.phonePaddingUnit}`;
    return (
      !panelOpened && (
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding,
            margin: "0 auto",
          }}
        >
          {controlsButtons}
        </div>
      )
    );
  };

  useEffect(() => {
    if (panelOpened) {
      setPanelContents(determinePanelContents(openTab));
    }
  }, [visiblePoints]);

  useEffect(() => {
    if (panelOpened && openTab === "Browse" && isThinking) {
      setPanelContents(<Spinner></Spinner>);
    }
  }, [isThinking]);

  // bookmark
  useEffect(() => {
    if (isMenuHidden) {
      setJustLoaded(false);
      closePanel(variables.sizes.controlsCardHiddenHeight);
      onHidePanel();
    } else {
      setPanelHeightPercent(variables.sizes.controlsCardInactiveHeight);
    }
  }, [isMenuHidden]);

  return (
    <Card
      data-testid="controls-card"
      animate={{ ...(!justLoaded && { height: `${panelHeightPercent}%` }) }}
      style={controlCardStyle}
    >
      {panelOpened && (
        <ControlsHeader
          selectedTab={openTab}
          onClick={() => {
            closePanel();
            trackEvent(`User closes ${openTab} tab`, {
              username: user?.username,
              email: user?.email,
            });
          }}
        />
      )}
      {controlsButtonsWrapper()}
      <Panel>{panelContents}</Panel>
    </Card>
  );
};

export default Controls;
