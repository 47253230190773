// TODO: delete this file?

import React, { CSSProperties } from "react";
import { Message } from "../api/points";
import { findUserByUid } from "../api/user";
// import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { motion } from "framer-motion";
import variables from "./variables";
import { format } from "timeago.js";

const filterGray = "#777";

const locationStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: 0,
  fontFamily: "monospace",
  fontSize: "0.7rem",
  marginRight: "0.3rem",
  marginTop: "0.2rem",
  color: "darkolivegreen",
};

const MessageListItem: React.FC<{
  message: Message;
  index?: number;
  showLocation: boolean;
  onClick?: React.MouseEventHandler;
}> = ({ message, showLocation, onClick }) => {
  const user = findUserByUid(message.uid);
  // const timeAgo = formatDistanceToNow(message.time, { addSuffix: true });

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      key={Math.random()}
      style={{ listStyleType: "none" }}
    >
      <div
        onClick={onClick}
        style={{
          paddingTop: "0.6rem",
          position: "relative",
          borderBottom: "1px solid #ddd",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          minHeight: "5rem",
        }}
      >
        {showLocation && <div style={locationStyle}>{message.location}</div>}
        <section
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "2rem",
              height: "2rem",
              borderRadius: "100%",
              backgroundColor: "black",
            }}
          ></div>
          <div>
            <span
              style={{ fontFamily: variables.fonts.header, fontSize: "0.9rem" }}
            >
              @{message.username}
            </span>
          </div>
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "70%",
            padding: "1.2rem 0.7rem",
            fontFamily: variables.fonts.body,
          }}
        >
          <div style={{ padding: "0 0 0.5rem 0", flexGrow: 1 }}>
            {message.message}
          </div>
          <div style={{ fontSize: "0.8rem", color: filterGray }}>
            {format(message.time)}
          </div>
        </section>
      </div>
    </motion.li>
  );
};

export default MessageListItem;
