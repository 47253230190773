// TODO: Delete this file?

/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import DropDown from "../shared/DropDown";

import { Message, Point } from "../api/points";
import variables from "../shared/variables";
import EmptyBrowsePanel from "./EmptyBrowsePanel";

// redux
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setCurrentView,
  setCenterMode,
  selectPosts,
} from "../redux/points/pointsSlice";

// components
import MessageListItem from "../components/MessageListItem/MessageListItem";

interface HeaderProps {
  locations: string[];
  topics: string[];
  onFilterChange: (props: FilterFnProps) => void;
}

interface FilterFnProps {
  filter: "location" | "topic" | "time" | "sort";
  value: string;
}

const filterGray = "#777";

const filterLocations = ({
  location,
  msgs,
}: {
  location: string;
  msgs: Message[];
}) => {
  const filteredMsgs =
    location === "here"
      ? msgs
      : msgs.filter((msg) => msg.location === location);
  return filteredMsgs;
};

const filterTopics = ({ topic, msgs }: { topic: string; msgs: Message[] }) => {
  const filteredMsgs =
    topic === "all topics"
      ? msgs
      : msgs.filter((msg) => msg.message.indexOf(topic) !== -1);
  return filteredMsgs;
};

const filterByTime = ({
  timeRange,
  msgs,
}: {
  timeRange: string;
  msgs: Message[];
}) => {
  const timeCutoffs: { [range: string]: number } = {
    "an hour": +new Date() - 1000 * 60 * 60,
    "a day": +new Date() - 1000 * 60 * 60 * 24,
    "a week": +new Date() - 1000 * 60 * 60 * 24 * 7,
    "a month": +new Date() - 1000 * 60 * 60 * 24 * 31,
    "a year": +new Date() - 1000 * 60 * 60 * 24 * 365,
    "five years": +new Date() - 1000 * 60 * 60 * 24 * 365 * 5,
  };

  return msgs.filter((msg) => +new Date(msg.time) >= timeCutoffs[timeRange]);
};

const sortMessages = ({
  sortColumn,
  msgs,
}: {
  sortColumn: string;
  msgs: Message[];
}) => {
  const likeSort = (a: Message, b: Message) => {
    return b.likes - a.likes;
  };

  const timeSort = (a: Message, b: Message) => {
    return new Date(b.time).getTime() - new Date(a.time).getTime();
  };

  let sortedMsgs = msgs;

  switch (sortColumn) {
    case "likes":
      sortedMsgs = msgs.sort(likeSort);
      break;
    case "time":
      sortedMsgs = msgs.sort(timeSort);
      break;
  }

  return [...sortedMsgs];
};

const getLocationsFromMessages = (msgs: Message[]): string[] => {
  return msgs.map((msg) => msg.location).sort();
};

const getTagsFromMessages = (msgs: Message[]): string[] => {
  const strMsgs = msgs.map((msg) => msg.message).sort();
  const tags = strMsgs
    .map((msg) =>
      [...`${msg} `.matchAll(/#(.*?)[.!?\\-\s\n]/g)].map((arr) => arr[1])
    )
    .flat();
  const dedupedTags = tags
    .filter((tag, index) => tags.indexOf(tag) === index)
    .sort();

  return dedupedTags;
};

const Header: React.FC<HeaderProps> = ({
  locations,
  topics,
  onFilterChange,
}) => {
  const locationOptions = ["here"].concat(locations);
  const topicOptions = ["all topics"].concat(topics);
  const timeOptions = [
    "an hour",
    "a day",
    "a week",
    "a month",
    "a year",
    "five years",
  ];
  const sortOptions = ["time", "likes"]; // ["time", "likes", "activity"]; // todo: allow comments and likes

  const [selectedLocation, setSelectedLocation] = useState(locationOptions[0]);
  const [selectedTopic, setSelectedTopic] = useState(topicOptions[0]);
  // TODO (urgent): make this a user setting
  const [selectedTime, setSelectedTime] = useState(timeOptions[1]);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const onSelectFilter = ({ filter, value }: FilterFnProps) => {
    switch (filter) {
      case "location":
        setSelectedLocation(value);
        break;
      case "topic":
        setSelectedTopic(value);
        break;
      case "time":
        setSelectedTime(value);
        break;
      case "sort":
        setSelectedSort(value);
        break;
    }

    onFilterChange({ filter, value });
  };

  return (
    <header
      style={{
        fontFamily: variables.fonts.body,
        fontWeight: 100,
        borderBottom: "3px dotted black",
        lineHeight: "1.9rem",
        padding: "0.3rem 0.6rem 0.6rem",
        color: filterGray,
        fontSize: "0.9rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div>
        {"Word from "}
        <DropDown
          onSelect={(value) => onSelectFilter({ filter: "location", value })}
          options={locationOptions}
        >
          {selectedLocation}
        </DropDown>{" "}
        {"about "}
        <DropDown
          onSelect={(value) => onSelectFilter({ filter: "topic", value })}
          options={topicOptions}
        >
          {selectedTopic}
        </DropDown>
        .<span> Starting from </span>{" "}
        <DropDown
          onSelect={(value) => onSelectFilter({ filter: "time", value })}
          options={timeOptions}
        >
          {selectedTime}
        </DropDown>
        <span> ago. </span>
        {"Sorting by "}
        <DropDown
          onSelect={(value) => onSelectFilter({ filter: "sort", value })}
          options={sortOptions}
        >
          {selectedSort}
        </DropDown>
        .
      </div>
    </header>
  );
};

const BrowsePanel: React.FC<{ points: Point[] }> = () => {
  const points = useAppSelector(selectPosts);

  const [displayMsgs, setDisplayMsgs] = useState(
    sortMessages({
      sortColumn: "time",
      msgs: points.map((point) => point.messages).flat(),
    })
  );
  const [isShowingLocation, setIsShowingLocation] = useState(true);

  // redux
  const dispatch = useAppDispatch();

  const onFilterChange = ({ filter, value }: FilterFnProps) => {
    switch (filter) {
      case "location":
        setDisplayMsgs(filterLocations({ location: value, msgs: displayMsgs }));
        if (value === "here") {
          setIsShowingLocation(true);
        } else {
          setIsShowingLocation(false);
        }
        break;
      case "topic":
        setDisplayMsgs(filterTopics({ topic: value, msgs: displayMsgs }));
        break;
      case "time":
        setDisplayMsgs(filterByTime({ timeRange: value, msgs: displayMsgs }));
        break;
      case "sort":
        setDisplayMsgs(sortMessages({ sortColumn: value, msgs: displayMsgs }));
        break;
    }
  };

  useEffect(() => {
    const msgs = points.map((point) => point.messages).flat();
    const sortedMsgs = sortMessages({ sortColumn: "time", msgs });
    setDisplayMsgs(sortedMsgs);
  }, [points]);

  useEffect(() => {
    dispatch(setCurrentView("Browse"));
    dispatch(setCenterMode("fixed"));
  }, [setCurrentView, setCenterMode]);

  const locations = getLocationsFromMessages(displayMsgs);
  const topics = getTagsFromMessages(displayMsgs);

  return displayMsgs.length ? (
    <div style={{ width: "100%" }}>
      <Header
        locations={locations}
        topics={topics}
        onFilterChange={onFilterChange}
      />
      <ul style={{ padding: 0, margin: 0 }}>
        {displayMsgs.map((msg, i) => (
          <MessageListItem
            key={i}
            index={i}
            message={msg}
            showLocation={isShowingLocation}
          />
        ))}
      </ul>
    </div>
  ) : (
    <EmptyBrowsePanel />
  );
};

export default BrowsePanel;
