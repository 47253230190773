import { User } from "../../redux/user/userSlice";

/*

  id: string;
  username: string;
  email: string;
  profileImage?: string;

  --

  email: "agama.africana@gmail.com"
  id: "AATQ1hzO3MgIbQwZtcEdQ970h0d2"
  profileImage: "https://lh3.googleusercontent.com/a/AATXAJyXoH9_P97OF18QC5ssldk4Ejyl9wLKXU-VE6pN=s96-c"
  username: "hassan_abudu"

  --

emmanuelagbayiza52@gmail.com (0543715677) [SJZKA2KvtfcKU6UTzRhtFOLvTE32]
jones.annobil77@gmail.com (0245651674) [J21gIbGxPzcsgVcCZdnjN1hEvBN2]
abenadew17@gmail.com (0245220120) [RaNJT0t33RcUXStCckSmNYX7UaT2]
elikemkojolord@gmail.com (0552712817) [VVrpHvAnyANj774Lqc8MAPT6yMF2]
66.mrgrey@gmail.com (0542706060)-Awuah Blessing [Ii3QvAxJFXPEiwfgDGudDytLKqq1]
hdaniella289@gmail.com (0558392744)-Daniella Xornam Yawa Howusu [CsZDNPQQ20Tl4KNatMvaB9ODDjo2]
jennykashkayne@gmail.com (0596729535) [eP8jz3MUjjdt6CLbFk0hZWhlA1H2]
egbenusonia@gmail.com  (0545410428) [2QlFJRrGnNYlQHjhDGZ7XiTvtQ93]

*/

export const studentAmbassadors: { email: string; id: string }[] = [
  // emmanuelagbayiza52@gmail.com (0543715677) [SJZKA2KvtfcKU6UTzRhtFOLvTE32]
  {
    email: "emmanuelagbayiza52@gmail.com",
    id: "SJZKA2KvtfcKU6UTzRhtFOLvTE32",
  },
  // jones.annobil77@gmail.com (0245651674) [J21gIbGxPzcsgVcCZdnjN1hEvBN2]
  {
    email: "jones.annobil77@gmail.com",
    id: "J21gIbGxPzcsgVcCZdnjN1hEvBN2",
  },
  // abenadew17@gmail.com (0245220120) [RaNJT0t33RcUXStCckSmNYX7UaT2]
  {
    email: "abenadew17@gmail.com",
    id: "RaNJT0t33RcUXStCckSmNYX7UaT2",
  },
  // iamlordkeli@gmail.com (0552712817) [BHAZHN9ADFcSbR64Kt33hSiyKv43]
  {
    email: "iamlordkeli@gmail.com",
    id: "BHAZHN9ADFcSbR64Kt33hSiyKv43",
  },
  // elikemkojolord@gmail.com (0552712817) [VVrpHvAnyANj774Lqc8MAPT6yMF2]
  {
    email: "elikemkojolord@gmail.com",
    id: "VVrpHvAnyANj774Lqc8MAPT6yMF2",
  },
  // 66.mrgrey@gmail.com (0542706060)-Awuah Blessing [Ii3QvAxJFXPEiwfgDGudDytLKqq1]
  {
    email: "66.mrgrey@gmail.com",
    id: "Ii3QvAxJFXPEiwfgDGudDytLKqq1",
  },
  // hdaniella289@gmail.com (0558392744)-Daniella Xornam Yawa Howusu [CsZDNPQQ20Tl4KNatMvaB9ODDjo2]

  {
    email: "hdaniella289@gmail.com",
    id: "CsZDNPQQ20Tl4KNatMvaB9ODDjo2",
  },
  // jennykashkayne@gmail.com (0596729535) [eP8jz3MUjjdt6CLbFk0hZWhlA1H2]
  {
    email: "jennykashkayne@gmail.com",
    id: "eP8jz3MUjjdt6CLbFk0hZWhlA1H2",
  },
  // egbenusonia@gmail.com  (0545410428) [2QlFJRrGnNYlQHjhDGZ7XiTvtQ93]
  {
    email: "egbenusonia@gmail.com",
    id: "2QlFJRrGnNYlQHjhDGZ7XiTvtQ93",
  },
  {
    email: "agama.africana@gmail.com",
    id: "l4UBdnzJ97SvW50TTxDOBBpeidF2",
  },
  /* ... */
];
