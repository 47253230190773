import {
  collection,
  writeBatch,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "./firebase-config";
import { Point } from "../redux/points/types";

// * Function that migrated posts and queries to firebase
export const addPointsToDatabase = async (dataToAdd: any) => {
  const updatedData = dataToAdd.map((data: any, i: any) => {
    return {
      id: i + 1,
      x: data.x,
      y: data.y,
      type: data.type,
      messages: data.messages.map((message: any) => {
        return {
          message: message.message,
          time: message.time.toISOString(),
          uid: message.uid,
          location: message.location,
          likes: message.likes,
          username: `user${i + 1}`,
        };
      }),
    };
  });

  const batch = writeBatch(db);
  const collectionRef = collection(db, "points");

  updatedData.forEach((obj: any) => {
    const newDocRef = doc(collectionRef);
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

export const addNewPoint = async (point: Point) => {
  const pointsRef = collection(db, "points");

  try {
    await addDoc(pointsRef, point);
  } catch (error) {
    console.log(error);
  }
};

export const getPosts = async () => {
  const q = query(collection(db, "points"), where("type", "==", "Post"));

  try {
    const querySnapshot = await getDocs(q);

    const posts = querySnapshot.docs.map((postDoc) => {
      return {
        ...postDoc.data(),
      };
    });

    return posts;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getQueries = async () => {
  const q2 = query(collection(db, "points"), where("type", "==", "Query"));

  try {
    const querySnapshot = await getDocs(q2);

    const queries = querySnapshot.docs.map((queryDoc) => {
      return {
        ...queryDoc.data(),
      };
    });

    return queries;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const likePost = async (
  uid: string,
  username: string,
  pointId: string,
  message: any
) => {
  const postRef = doc(db, "points", pointId);

  const { likesArray, likes } = message;

  // check if already liked
  const alreadyLiked = likesArray.find((id: string) => id === uid);

  let description = "";

  // if already liked return new array excluding the uid
  if (alreadyLiked) {
    description = `${username} unliked the post post ${message.username}`;

    const filtered = likesArray.filter((id: string) => id !== uid);

    await updateDoc(postRef, {
      messages: [
        {
          ...message,
          likesArray: filtered,
          likes: likes === 0 ? likes : likes - 1,
        },
      ],
    });
  } else {
    description = `${username} liked the post post ${message.username}`;
    // else add uid to likesArray
    await updateDoc(postRef, {
      messages: [
        {
          ...message,
          likesArray: [...likesArray, uid],
          likes: likes + 1,
        },
      ],
    });
  }

  return description;
};

export const uploadImages = async (images: File[], user: any) => {
  const imageUrls = await Promise.all(
    images.map(async (imageFile) => {
      const imageRef = ref(
        storage,
        `${user.username}_${user.id}/${new Date().toISOString()}_${
          imageFile.name
        }`
      );
      await uploadBytes(imageRef, imageFile);

      return getDownloadURL(imageRef);
    })
  );

  return imageUrls;
};

export const uploadVideo = async (videoFile: File, user: any) => {
  const videoRef = ref(
    storage,
    `${user.username}_${user.id}/${new Date().toISOString()}_${videoFile.name}`
  );

  await uploadBytes(videoRef, videoFile);

  return getDownloadURL(videoRef);
};

export const addComment = async (comment: any, message: any) => {
  const postRef = doc(db, "points", message.pointId);
  const comments = message.comments;

  await updateDoc(postRef, {
    messages: [
      {
        ...message,
        comments: [...comments, comment],
      },
    ],
  });
};

export const likeComment = async (
  commentId: any,
  userId: any,
  message: any
) => {
  const postRef = doc(db, "points", message.pointId);
  const comments = message.comments;

  const newComments = comments.map((comt: any) => {
    if (comt.id === commentId) {
      const { likesArray, likes } = comt;

      // check if already liked
      const alreadyLiked = likesArray.find((id: string) => id === userId);

      // if already liked return new array excluding the uid
      if (alreadyLiked) {
        return {
          ...comt,
          likesArray: likesArray.filter((id: string) => id !== userId),
          likes: likes - 1,
        };
      } else {
        return {
          ...comt,
          likesArray: [...likesArray, userId],
          likes: likes + 1,
        };
      }
    } else {
      return comt;
    }
  });

  await updateDoc(postRef, {
    messages: [
      {
        ...message,
        comments: newComments,
      },
    ],
  });
};
