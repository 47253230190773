const Blog = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Blog</h1> */}
      <p>
        Coming soon! Here, we will share news and updates about our journey as a
        company
      </p>
    </div>
  );
};

export default Blog;
