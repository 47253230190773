const TermsOfService = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Terms of Service</h1> */}
      <p>
        We do not have a lawyer on the team (yet) but just know that we are not
        making any guarantees about this app. We will try our best to give you a
        great user experience, but use it at your own peril.
      </p>
      <p>
        Also, do not use this app for any illegal activities, otherwise we will
        kick you off the platform. That simple.
      </p>
    </div>
  );
};

export default TermsOfService;
