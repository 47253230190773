import React from "react";

// mui
import { TextField } from "@mui/material";

interface InputProps {
  label: string;
  value?: string;
  onChange?: (event: React.ChangeEvent) => void;
  error?: boolean;
  helperText?: string;
  type?: string;
}

const TextInput: React.FC<InputProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
  type,
}) => {
  return (
    <TextField
      label={label}
      variant="standard"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      type={type}
      sx={{
        width: {
          xs: "250px",
          sm: "300px",
          md: "300px",
          lg: "300px",
          xl: "300px",
        },
        "& label": {
          color: "primary.main",
          paddingLeft: 1,
          fontSize: "14px",
        },
        marginRight: 1,
      }}
    />
  );
};

export default TextInput;
