import { configureStore } from "@reduxjs/toolkit";

// redux persist
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";

// reducers
import userReducer from "./user/userSlice";
import pointsReducer from "./points/pointsSlice";
import noPostsReducer from "./noPosts/noPostsSlice";
import drawerReducer from "./drawer/drawerSlice";

const reducers = combineReducers({
  user: userReducer,
  points: pointsReducer,
  noPosts: noPostsReducer,
  drawer: drawerReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
