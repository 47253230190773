import React from "react";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../shared/getUserId";
import { IoEyeSharp } from "react-icons/io5";
import { Suggestion } from "./index";

// firebase
import { unVote, vote } from "../../firebase/suggestion";

// mui
import { Stack, Typography, Button, IconButton, Tooltip } from "@mui/material";

interface ItemProps {
  suggestion: Suggestion;
}

const SuggestionItem: React.FC<ItemProps> = ({ suggestion }) => {
  const navigate = useNavigate();

  const handleVote = async (sugg: Suggestion) => {
    const { votes } = sugg;

    try {
      if (votes.includes(getUserId())) {
        await unVote(getUserId(), sugg.id);
        return;
      }

      await vote(getUserId(), sugg.id);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleDelete = async (suggId: string) => {
  //   try {
  //     await deleteSuggestion(suggId);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ flex: 1 }}
        spacing={2}
      >
        {/* view details button */}
        <Tooltip title="View Details">
          <IconButton
            disableRipple
            onClick={() =>
              navigate(`/suggest-a-feature/details/${suggestion.id}`)
            }
          >
            <IoEyeSharp />
          </IconButton>
        </Tooltip>
        <Typography
          sx={{
            textTransform: "capitalize",
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "16px",
              lg: "16px",
              xl: "16px",
            },
          }}
        >
          {suggestion.title}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        {/* number of votes */}
        <Typography sx={{ marginRight: 2 }}>
          {suggestion.votes.length}
        </Typography>
        {/* vote button */}
        <Button
          variant={
            suggestion.votes.includes(getUserId()) ? "outlined" : "contained"
          }
          disableElevation
          disableRipple
          size="small"
          sx={{ textTransform: "capitalize", fontSize: "14px" }}
          onClick={() => handleVote(suggestion)}
        >
          {suggestion.votes.includes(getUserId()) ? "Undo" : "Vote"}
        </Button>
        {/* delete button */}
        {/* <IconButton
          disableRipple
          onClick={() => {
            if (suggestion.userId === getUserId()) {
              handleDelete(suggestion.id);
            }
          }}
        >
          <IoTrashSharp color="red" size="1rem" />
        </IconButton> */}
      </Stack>
    </Stack>
  );
};

export default SuggestionItem;
