import styled from "@emotion/styled";

import React, { useState, useEffect, useRef } from "react";
import variables from "../shared/variables";

// redux
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  selectNumEmptyPageLoads,
  selectMusicObject,
  setMusicObject,
} from "../redux/noPosts/noPostsSlice";

import UnstyledButton from "../shared/UnstyledButton";
import { LocalStorageUtils } from "../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";

import ReactAudioPlayer from "react-audio-player";
const songFile =
  "https://meyian-public-storage.s3.amazonaws.com/nothing_to_see_clip.mp3";

const EMPTY_PAGE_LOADS_KEY = "num_of_empty_results_loads";

const getRefreshes = (): number =>
  parseInt(LocalStorageUtils.get(EMPTY_PAGE_LOADS_KEY) || "") || 0;

const setRefreshes = (number: number) => {
  LocalStorageUtils.set(EMPTY_PAGE_LOADS_KEY, number.toString());
};

const getNumLoads = (): number => Math.ceil(getRefreshes() / 2);

// const resetRefreshes = (): void => {
//   set(EMPTY_PAGE_LOADS_KEY, "0");
// };

const H1 = styled.h1`
  font-size: 1.5rem;
  font-family: ${variables.fonts.body};
`;

const Div = styled.div`
  padding: 0 1rem;
`;

const P = styled.p`
  font-family: ${variables.fonts.body};
`;

const nothingnessPossibilities = [
  <H1 key={1}>Nothing To See.</H1>,
  <H1 key={2}>Nothing here yet. 😕</H1>,
  <H1 key={3}>Nothing here for now.</H1>,
  <H1 key={4}>Nobody&apos;s posted content here yet.</H1>,
];

const defaultNothingness = <H1>Nothing To See.</H1>;

const NothingnessAfterQuiz = () => {
  const getRandomIndex = () =>
    Math.floor(Math.random() * nothingnessPossibilities.length);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(getRandomIndex());
  });

  return nothingnessPossibilities[index];
};

const PlayButton: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => (
  <FontAwesomeIcon
    onClick={onClick}
    style={{ margin: "0.7rem 0.4rem", fontSize: "1.5rem" }}
    icon={faPlayCircle}
  />
);
const PauseButton: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => (
  <FontAwesomeIcon
    onClick={onClick}
    style={{ margin: "0.7rem 0.4rem", fontSize: "1.5rem" }}
    icon={faPauseCircle}
  />
);

const getAudioElement = (
  reactAudioPlayerRef: React.RefObject<ReactAudioPlayer>
) => {
  const audioPlayer = reactAudioPlayerRef?.current || false;
  let audioEl: HTMLAudioElement | null = null;
  if (audioPlayer !== false) {
    audioEl = (
      audioPlayer as {
        audioEl: { current: HTMLAudioElement };
      }
    ).audioEl.current;
  }

  return audioEl;
};

const EmptyBrowsePanel = () => {
  const rapRef = useRef(null);
  const [panelContents, setPanelContents] =
    useState<JSX.Element>(defaultNothingness);

  // redux
  const dispatch = useAppDispatch();
  const numEmptyPageLoads = useAppSelector(selectNumEmptyPageLoads);
  const musicObject = useAppSelector(selectMusicObject);

  const musicQuestion = () => {
    return (
      <Div>
        <H1>Nothing to see. Speaking of which, do you like music?</H1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <UnstyledButton
            onClick={() => {
              dispatch(
                setMusicObject({
                  ...musicObject,
                  likesMusic: true,
                  hasAnsweredMusicQuestion: true,
                })
              );
            }}
          >
            Yes
          </UnstyledButton>
          <UnstyledButton
            onClick={() => {
              dispatch(
                setMusicObject({
                  ...musicObject,
                  likesMusic: false,
                  hasAnsweredMusicQuestion: true,
                })
              );
            }}
          >
            No
          </UnstyledButton>
        </div>
      </Div>
    );
  };

  // bookmark
  useEffect(() => {
    const numRefreshes = getRefreshes() + 1;
    setRefreshes(numRefreshes);
  });

  // todo make this function way smaller, and probably smarter too
  useEffect(() => {
    const numLoads = getNumLoads();

    if (numLoads === 2) {
      setPanelContents(
        <div style={{ maxWidth: "500px" }}>
          <H1>
            Nothing to see. We&apos;re a new app, which probably explains the
            absence of content here. Zoom out and maybe find more content that
            way?
          </H1>
        </div>
      );
    }

    // Todo: Make a component instead of a rendering function
    if (numLoads >= variables.numbers.numEmptyPostPageLoadsForQuiz) {
      setPanelContents(musicQuestion());
    }

    if (musicObject.returnToDefault === true) {
      setPanelContents(<NothingnessAfterQuiz />);
    } else if (musicObject.likesFourTet !== undefined) {
      let newContent: JSX.Element;
      let readingTime;

      if (musicObject.likesFourTet === true) {
        newContent = (
          <Div>
            <P>
              How nice that we have similar tastes in music! Keep using the app
              and I&rsquo;ll try out more music suggestions on ya.
            </P>
          </Div>
        );
        readingTime = 4000;
      } else {
        newContent = (
          <Div>
            <P>Oh, okay! I kind of like it, but to each their own.</P>
          </Div>
        );
        readingTime = 2000;
      }

      setPanelContents(newContent);
      setTimeout(() => {
        dispatch(setMusicObject({ ...musicObject, returnToDefault: true }));
      }, readingTime);
    } else if (musicObject.hasAnsweredMusicQuestion === true) {
      let newContent: JSX.Element;

      if (musicObject.likesMusic) {
        newContent = (
          <div>
            <Div>
              <P style={{ marginBottom: 0 }}>
                Oh cool! Me too. Listen to this and tell me what you think.
                It&rsquo;s a song called &ldquo;Nothing to See&rdquo; by a dude
                called Four Tet.
              </P>
            </Div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              {!musicObject.isPlaying ? (
                <PlayButton
                  onClick={() => {
                    dispatch(
                      setMusicObject({ ...musicObject, isPlaying: true })
                    );
                    const audioEl: HTMLAudioElement | null =
                      getAudioElement(rapRef);
                    if (audioEl !== null) audioEl.play();
                  }}
                />
              ) : (
                <PauseButton
                  onClick={() => {
                    dispatch(
                      setMusicObject({ ...musicObject, isPlaying: false })
                    );
                    const audioEl: HTMLAudioElement | null =
                      getAudioElement(rapRef);
                    if (audioEl !== null) audioEl.pause();
                  }}
                />
              )}
              <ReactAudioPlayer ref={rapRef} src={songFile} />
            </div>
            <Div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <UnstyledButton
                style={{ fontWeight: 700 }}
                onClick={() => {
                  const audioEl: HTMLAudioElement | null =
                    getAudioElement(rapRef);
                  if (audioEl !== null) audioEl.pause();
                  dispatch(
                    setMusicObject({
                      ...musicObject,
                      likesFourTet: true,
                    })
                  );
                }}
              >
                Like it
              </UnstyledButton>
              <UnstyledButton
                style={{ fontWeight: 700 }}
                onClick={() => {
                  const audioEl: HTMLAudioElement | null =
                    getAudioElement(rapRef);
                  if (audioEl !== null) audioEl.pause();
                  dispatch(
                    setMusicObject({
                      ...musicObject,
                      likesFourTet: false,
                    })
                  );
                }}
              >
                It&rsquo;s weird
              </UnstyledButton>
            </Div>
          </div>
        );
      } else {
        newContent = (
          <Div>
            <P>Oh, okay! Never mind, then. :)</P>
          </Div>
        );
        setTimeout(() => {
          dispatch(setMusicObject({ ...musicObject, returnToDefault: true }));
        }, 2000);
      }
      setPanelContents(newContent);
    }
  }, [numEmptyPageLoads, musicObject]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {panelContents}
    </div>
  );
};

export default EmptyBrowsePanel;
