import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "./css/main.css";
import "react-awesome-lightbox/build/style.css";

// mui theme provider
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./utils/muiTheme";

// redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";

// redux persist
import { PersistGate } from "redux-persist/integration/react";

// router
import { BrowserRouter as Router } from "react-router-dom";

import "./global.css";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
