import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Suggestion } from "./index";

// firebase
import { db } from "../../firebase/firebase-config";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";

// mui
import { Stack, Typography } from "@mui/material";

// components
import Template from "./Template";
import SuggestionItem from "./SuggestionItem";

const VoteForSuggestion: React.FC = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState(false);

  // fetches suggested features when component mounts
  useEffect(() => {
    const q = query(
      collection(db, "suggestions"),
      orderBy("createdAt", "desc")
    );

    setLoading(true);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          userId: doc.data().userId,
          title: doc.data().title,
          description: doc.data().description,
          votes: doc.data().votes,
        };
      });

      setSuggestions(data);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Template to="/suggest-a-feature">
      <Typography sx={{ mb: 5, textAlign: "center", fontWeight: 200 }}>
        Vote for an existing suggestion
      </Typography>

      {loading && (
        <Stack justifyContent="center" alignItems="center">
          <ClipLoader />
        </Stack>
      )}

      {suggestions.length === 0 && !loading && (
        <Stack justifyContent="center" alignItems="center">
          <Typography>No Suggested Features</Typography>
        </Stack>
      )}

      {suggestions.length > 0 && !loading && (
        <Stack spacing={3} sx={{ height: "340px", overflowX: "auto" }}>
          {suggestions.map((suggestion) => {
            return (
              <SuggestionItem suggestion={suggestion} key={suggestion.id} />
            );
          })}
        </Stack>
      )}
    </Template>
  );
};

export default VoteForSuggestion;
