import React from "react";
import { NavLink } from "react-router-dom";

// mui
import { Link } from "@mui/material";

const CustomLink: React.FC<{ to: string; text: string }> = ({ to, text }) => {
  return (
    <Link
      underline="none"
      component={NavLink}
      to={to}
      sx={{
        fontSize: "16px",
        fontFamily: "Helvetica",
      }}
    >
      {text}
    </Link>
  );
};

export default CustomLink;
