// TODO: Delete this file

import { DateUtils } from "../utils/index";

import { Coordinate, BoundingBox } from "../shared/types";

export type Location = {
  name: string;
  position: Coordinate;
};

export type Comment = {
  text: string;
  likes: number;
  likesArray: string[];
  username: string;
  time: Date | string;
  userId: string;
  id: number;
};

// x - Latitude, y - longitude
export type Message = {
  message: string;
  time: Date | string;
  uid: number;
  location: string;
  likes: number;
  likesArray?: string[];
  username?: string;
  userId?: string;
  pointId?: string;
  profileImage?: string;
  images?: string[];
  video?: string;
  comments?: Comment[];
};

export type Point = {
  pointId?: string;
  id: number;
  x: number;
  y: number;
  messages: Message[];
  type: "Post" | "Query";
};

export type PostSearchResult = {
  msgs: Message[];
  point: Point;
};

// TODO: delete randomLikeNumber & randomUid & others in this file?
const randomLikeNumber = (): number => {
  return Math.ceil(Math.random() * 10);
};

const randomUid = (): number => {
  return Math.ceil(Math.random() * 5);
};

const locations: Location[] = [
  {
    name: "Accra Mall",
    position: {
      x: 5.622356549317668,
      y: -0.17332864510972237,
    },
  },
  {
    name: "Bush Canteen",
    position: {
      x: 5.648709323995665,
      y: -0.1811730866154947,
    },
  },
  {
    name: "Legon Bypass",
    position: {
      x: 5.6407200487652975,
      y: -0.20159744510974462,
    },
  },
];

export const pointsArr: Point[] = [
  {
    id: 1,
    x: 5.6633193169782885,
    y: -0.2286577956144217,
    type: "Post",
    messages: [
      {
        message:
          "“Be a rainbow in someone else’s cloud.” — Maya Angelou #inpiration",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 2,
    x: 5.664354689890077,
    y: -0.2059623393147994,
    type: "Post",
    messages: [
      {
        message:
          "TIL the name Sanka of Sanka decaffeinated coffee is a portmanteau of the French Sans (without) and Ka for caffeine. #coffee #themoreyouknow",
        time: DateUtils.randomDate(-1),
        uid: 2,
        location: "Das Boot",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 3,
    x: 5.623580991856183,
    y: -0.2545639940039587,
    type: "Post",
    messages: [
      {
        message:
          "TIL Maggots and Leeches are still extensively used in modern medicine. Maggots are used to clean necrotic flesh out of old wounds, and leeches are used to restore blood flow to areas following microsurgeries! #medicine #themoreyouknow",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Petra's Pilates",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 4,
    x: 5.585335477914368,
    y: -0.23195857321830318,
    type: "Post",
    messages: [
      {
        message:
          "Wow, it's 10 AM and Accra Mall is already crowded. Is there some kind of promotion going on there?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Accra Mall",
        likes: randomLikeNumber(),
      },
      {
        message:
          "All phones at the Samsung store are 50% off. Today is the last day this promotion is running, so get over here quickly and grab yours!",
        time: DateUtils.randomDate(-1),
        uid: 2,
        location: "Accra Mall",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 5,
    x: 5.5988341805630055,
    y: -0.20370179723623388,
    type: "Post",
    messages: [
      {
        message:
          "I have 2 MacBook Pros, a custom-built PC running Windows & WSL2, and another laptop on Linux... All for sale here.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 6,
    x: 5.673071452638232,
    y: -0.1618817687827712,
    type: "Post",
    messages: [
      {
        message: "Stephen Appiah announces he's leaving Asante Kotoko today.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 7,
    x: 5.66969723751324,
    y: -0.16979366605775065,
    type: "Post",
    messages: [
      {
        message:
          "COVID exploded in my ICUs this week. I was looking as far as Cincinnati to find an ecmo circuit for a young patient yesterday — while we have a few circuits available locally, there are no nurses available. Bed capacity is more than an empty bed, it’s a team to staff it, too.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 8,
    x: 5.58955385593642,
    y: -0.24043560601292402,
    type: "Post",
    messages: [
      {
        message: "Moral of the story: NEVER GIVE UP!!! #strivinghard",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 9,
    x: 5.578304780394962,
    y: -0.21387423658977883,
    type: "Post",
    messages: [
      {
        message:
          "This year has been the best year of my life. I’ve never been happier in my entire life. What the heck!",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 10,
    x: 5.623299783994488,
    y: -0.2678446787155313,
    type: "Post",
    messages: [
      {
        message: "So proud of Emmanuel Kamansah from Akropong.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 11,
    x: 5.656481371764817,
    y: -0.26417129783786225,
    type: "Post",
    messages: [
      {
        message:
          "My #THIB vinyl and Cd just arrived. Get to add it to my collection <3. @isaiahrashad",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 12,
    x: 5.653950639602296,
    y: -0.2774519825494349,
    type: "Post",
    messages: [
      {
        message:
          "Did you know that it is possible to be rejected as a frontend web dev candidate for… [checks email; squints] caring too much about accessibility? I love this industry. I learn new things every day! #boycott",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 13,
    x: 5.619081649765628,
    y: -0.2152870753888823,
    type: "Post",
    messages: [
      {
        message:
          "Worrying is a waste of time. Good and bad things happen in life, you just have to keep living and not stress over what you can't control.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 14,
    x: 5.645233588587963,
    y: -0.1839220540487853,
    type: "Post",
    messages: [
      {
        message:
          "No one really tells you how emotionally taxing parenting can be. #sweatsmile",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 15,
    x: 5.657324946692236,
    y: -0.2737786016717659,
    type: "Post",
    messages: [
      {
        message:
          "Dear coffee shops, hot coffee poured over ice is not cold brew. Thank you for coming to my TED Talk",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 16,
    x: 5.6753209184225,
    y: -0.13249472176141908,
    type: "Post",
    messages: [
      {
        message:
          "A local shares what it's really like to visit East Legon right now",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 17,
    x: 5.621050116209336,
    y: -0.33453067003321496,
    type: "Post",
    messages: [
      {
        message:
          "Discover the most reviewed places in your city. Sometimes a restaurant with most reviews and a low rating doesn't mean is bad! Or the other way around!",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 18,
    x: 5.670756224830831,
    y: -0.34034860140155626,
    type: "Post",
    messages: [
      {
        message:
          "It’s a safe bet that many Ghanaians are feeling a bit of whiplash these past few weeks",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 19,
    x: 5.705863681820765,
    y: -0.03916771049947194,
    type: "Post",
    messages: [
      {
        message:
          "Doesn't matter if you have a fancy MacBook or a low-end laptop. As long as you're writing code and improving yourself, I'd say you're doing a-ok. Thumbs up.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 20,
    x: 5.609955339539232,
    y: -0.11833525896516274,
    type: "Post",
    messages: [
      {
        message:
          "An anti-government protest turned violent on Saturday, with police firing teargas and rubber bullets and demonstrators hurling rocks and firecrackers.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 21,
    x: 5.739256585583471,
    y: -0.2302024470145083,
    type: "Post",
    messages: [
      {
        message: "Why is Linux safe? Hackers peak through Windows only.",
        time: DateUtils.randomDate(-1),
        uid: randomUid(),
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
];

export const questions: Point[] = [
  {
    id: 1,
    x: 5.562245427676104,
    y: -0.1565702374962998,
    type: "Query",
    messages: [
      {
        message: "What is the best soup you can get at ANC mall?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 2,
    x: 5.572295959148464,
    y: -0.1440240323469707,
    type: "Query",
    messages: [
      {
        message: "Where can I get Sanka coffee?",
        time: DateUtils.randomDate(-1),
        uid: 2,
        location: "Das Boot",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 3,
    x: 5.5668138723755565,
    y: -0.13208983720492595,
    type: "Query",
    messages: [
      {
        message: "Where is the department of computer science?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 4,
    x: 5.596051076516122,
    y: -0.17982661777310502,
    type: "Query",
    messages: [
      {
        message: "What time does mass start?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 5,
    x: 5.590873761239988,
    y: -0.17982661777310502,
    type: "Query",
    messages: [
      {
        message:
          "Is there any connection between the university of Wisconsin and the state of Wisconsin in America?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 6,
    x: 5.614497627054908,
    y: -0.22614834327622255,
    type: "Query",
    messages: [
      {
        message:
          "Has the quality of pizza changed ever since Eddy's pizza became Cheezy Pizza?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 7,
    x: 5.616023075579399,
    y: -0.22793661453545233,
    type: "Query",
    messages: [
      {
        message: "Is there a dentist nearby?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 8,
    x: 5.638904323607417,
    y: -0.24556385980500353,
    type: "Query",
    messages: [
      {
        message: "How strong is the religious content of these schools?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 9,
    x: 5.640938168712674,
    y: -0.18476263699118917,
    type: "Query",
    messages: [
      {
        message: "How bumpy is this road?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 10,
    x: 5.703222029520477,
    y: -0.020972033007958593,
    type: "Query",
    messages: [
      {
        message: "Is traffic here bad?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 11,
    x: 5.690602755667698,
    y: -0.026044797541789247,
    type: "Query",
    messages: [
      {
        message: "Do they have ponies?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 12,
    x: 5.677983204851377,
    y: -0.013996981773941456,
    type: "Query",
    messages: [
      {
        message: "What animals do they have in the zoo?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 13,
    x: 5.655582821921628,
    y: -0.06694396159579884,
    type: "Query",
    messages: [
      {
        message: "How is the traffic on this road?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 14,
    x: 5.710208989842709,
    y: -0.20069368914383046,
    type: "Query",
    messages: [
      {
        message: "How paradisaical is this place anyway?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
  {
    id: 15,
    x: 5.647859330261763,
    y: -0.122244841786329,
    type: "Query",
    messages: [
      {
        message: "Is the food here any good?",
        time: DateUtils.randomDate(-1),
        uid: 1,
        location: "Information Super-highway",
        likes: randomLikeNumber(),
      },
    ],
  },
];

const isPointInBoundingBox = (point: Point, box: BoundingBox) => {
  const isXWithin = point.x >= box.topLeft.x && point.x <= box.bottomRight.x;
  const isYWithin = point.y >= box.topLeft.y && point.y <= box.bottomRight.y;

  return isXWithin && isYWithin;
};

export const allPoints = (): Point[] => {
  return pointsArr;
};

export const allQuestions = (): Point[] => {
  return questions;
};

export const pointsInBoundingBox = (
  box: BoundingBox,
  points?: Point[]
): Point[] => {
  const availablePoints = points || allPoints();
  const visiblePoints = availablePoints.filter((point) =>
    isPointInBoundingBox(point, box)
  );

  return visiblePoints;
};

export const getMsgs = (points: Point[]) =>
  points.map((point) => point.messages).flat();

export const findPosts = (searchTerm: string): PostSearchResult[] => {
  const pointsWithTerm = allPoints().filter(
    (point) =>
      point.messages
        .map((msg) => msg.message)
        .filter((msg) => {
          const hasFoundSearchTerm =
            msg.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;

          return hasFoundSearchTerm;
        }).length > 0
  );

  const resultObjArray = pointsWithTerm.map((point) => {
    const msgs = point.messages.filter(
      (msg) =>
        msg.message.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
    return {
      msgs,
      point,
    };
  });

  return resultObjArray;
};

export const findLocations = (searchTerm: string): Location[] => {
  return locations.filter(
    (location) =>
      location.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  );
};
