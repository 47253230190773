import images from "../shared/images";

const preloadImages = (onComplete: () => void) => {
  Promise.all(
    images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image.path;
        img.onload = () => {
          resolve(undefined);
        };
        img.onerror = () => reject(new Error("Couldn't load image"));
      });
    })
  ).then(onComplete);
};

export default {
  preloadImages,
};
