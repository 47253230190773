const toSentenceCase = (str: string): string => {
  const words = str.split(" ");

  const result = words.map((word, index) => {
    return words.length === 3 && index === 1
      ? word
      : word[0].toUpperCase() + word.substring(1, word.length);
  });

  return result.join(" ");
};

export default toSentenceCase;
