import { useEffect, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// firebase
import { onSnapshot } from "firebase/firestore";
import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { createUser } from "../firebase/user";
import { getPosts, getQueries } from "../firebase/points";

// redux
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setUser, selectUser } from "../redux/user/userSlice";
import { setPosts, setQueries } from "../redux/points/pointsSlice";

// screens
import HomeScreen from "../screens/Home";
import { modalWebsitePages } from "../screens/ModalWebsite/ModalWebsite";

import Dashboard from "../screens/Dashboard/Dashboard";

import {
  drawerPages,
  PATH_MATCHING_STRICT,
  PATH_MATCHING_REGEX,
} from "../components/Drawer/Drawer";

// tracking
import { setUserId, getUserId } from "../shared/getUserId";
import { trackIdentity, trackEvent } from "../analytics/SegmentAnalytics";
import queryString from "query-string";
import { decryptReferralCode } from "../utils/encryption_utils";

declare global {
  interface Window {
    analytics: any;
  }
}

const useSegmentPageViews = () => {
  const location = useLocation();
  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
};

const App = () => {
  useSegmentPageViews();
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUser);
  const location = useLocation();

  const storeReferralInfo = useCallback(() => {
    const search = location.search;
    const referralCode =
      (queryString.parse(search).referral_code as string) || "";
    const decryptedCode = decryptReferralCode(referralCode);

    if (decryptedCode === null) {
      return;
    }
    const [referrer, source] = decryptedCode;

    if (referrer) {
      trackEvent("User clicked on referral link", {
        personWhoReferred: referrer,
        userWhoClicked: getUserId(),
        source,
        pathname: location.pathname,
      });
    }
  }, []);

  // in case of future on-load activities
  const onLoadApp = useCallback(() => {
    storeReferralInfo();
  }, [location]);

  // check if user is logged in
  useEffect(() => {
    const unsubscribeFromAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRef = await createUser(user);

          onSnapshot(userRef, (snapShot) => {
            dispatch(
              setUser({
                id: snapShot.id,
                ...snapShot.data(),
              })
            );
          });
        } catch (error) {
          console.log(error);
          // dispatch(setUser(null));
        }
      } else {
        dispatch(setUser(user));
      }
    });

    // unsubscribe from auth listener
    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  // check if user is logged in
  useEffect(() => {
    setUserId(userState?.id);
    trackIdentity(getUserId(), {
      username: userState?.username ? userState?.username : undefined,
      email: userState?.email ? userState.email : undefined,
    });
  }, [userState]);

  // get posts
  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getPosts();
      dispatch(setPosts(posts));
    };

    fetchPosts();
  }, []);

  // get queries
  useEffect(() => {
    const fetchQueries = async () => {
      const queries = await getQueries();
      dispatch(setQueries(queries));
    };

    fetchQueries();
  }, []);

  useEffect(() => {
    onLoadApp();
  }, []);

  const websiteModalRoutes = modalWebsitePages.map((page) =>
    page.path ? (
      <Route key={page.title} path={page.path} element={<HomeScreen />} />
    ) : null
  );

  // TODO: move fast break things; will get this working and tested. This DRYs the routes.
  // const drawerRoutes = drawerPages.map((page) => {
  //   const willCreateRoute =
  //     page.path &&
  //     page.pathMatching === PATH_MATCHING_STRICT &&
  //     page.path !== "/";
  //   return willCreateRoute ? (
  //     <Route key={page.title} path={page.path || ""} element={<HomeScreen />} />
  //   ) : null;
  // });

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/login" element={<HomeScreen />} />
        <Route path="/register" element={<HomeScreen />} />
        <Route path="/user-profile" element={<HomeScreen />} />
        <Route path="/reset-password" element={<HomeScreen />} />
        <Route path="/share" element={<HomeScreen />} />
        <Route path="/suggest-a-feature" element={<HomeScreen />} />
        <Route path="/suggest-a-feature/add" element={<HomeScreen />} />
        <Route path="/suggest-a-feature/vote" element={<HomeScreen />} />
        <Route
          path="/suggest-a-feature/details/:suggestionId"
          element={<HomeScreen />}
        />
        {websiteModalRoutes}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:ambassadorId" element={<Dashboard />} />
        <Route path="/post/:postId" element={<HomeScreen />} />
        <Route path="/dashboard/posts-per-day" element={<Dashboard />} />
      </Routes>
    </>
  );
};

export default App;
