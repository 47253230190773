import React, { useEffect, useState } from "react";
import { Message, Point } from "../../api/points";

// redux
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectQueries, setCurrentView } from "../../redux/points/pointsSlice";

// components
import Header from "./Header";
import AskTab from "./AskTab";
import AnswerTab from "./AnswerTab";
import BrowseTab from "./BrowseTab";

const QueryPanel: React.FC<{
  visiblePoints: Point[];
  onCreatePost: () => void;
}> = ({ onCreatePost }) => {
  const dispatch = useAppDispatch();
  const queries = useAppSelector(selectQueries);

  const [selectedTab, setSelectedTab] = useState("ask");
  const [contents, setContents] = useState<JSX.Element>(<></>);
  const [msgs, setMsgs] = useState<Message[]>(
    queries.map((point) => point.messages).flat()
  );

  useEffect(() => {
    const visibleMsgs = queries.map((point) => point.messages).flat();
    setMsgs(visibleMsgs);
  }, [queries]);

  useEffect(() => {
    switch (selectedTab) {
      case "ask":
        setContents(
          <AskTab
            onCreatePost={() => setSelectedTab("browse")}
            locations={["Accra", "Ghana", "West Africa", "Africa", "the World"]}
          />
        );
        break;

      case "answer":
        setContents(
          <AnswerTab onCreatePost={onCreatePost} visiblePoints={queries} />
        );
        break;

      case "browse":
        setContents(<BrowseTab visiblePoints={queries} />);
        break;

      default:
        setContents(<></>);
    }
  }, [selectedTab, queries, msgs]);

  useEffect(() => {
    dispatch(setCurrentView("Query"));
  }, []);

  return (
    <>
      <Header
        selectedTab={selectedTab}
        onSetSelected={(tab) => setSelectedTab(tab)}
      />
      <div
        style={{
          height: "calc(100% - 40px)",
          position: "absolute",
          width: "100%",
        }}
      >
        {contents}
      </div>
    </>
  );
};

export default QueryPanel;
