const AboutUs = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>About Us</h1> */}
      <p>
        We are a small remote team of engineers based out of Ghana. We think
        that it is important for the internet to allow us to talk about ground
        realities, not just virtual content and goings on in cyberspace. We
        built Agama Africana with this aim in mind.
      </p>
    </div>
  );
};

export default AboutUs;
