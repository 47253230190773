/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import { MouseEvent, ChangeEvent, useState, useEffect } from "react";

import { modalWebsitePages } from "../ModalWebsite";
import AgamaHeader from "../../../shared/AgamaHeader";
import toSentenceCase from "../helpers/toSentenceCase";
import variables from "../../../shared/variables";

const MobileTemplate: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");

  const onToggle = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsDetailsOpen(!isDetailsOpen);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCurrentPath(event.currentTarget.value);
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, []);

  useEffect(() => {
    navigate(currentPath);
  }, [currentPath]);

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        flexDirection: "column",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <header>
        <AgamaHeader marginBottom="18px" />

        <details
          open={isDetailsOpen}
          css={css`
            border: 1px solid ${variables.colors.borders};
            border-radius: 4px;
            padding: 10px 10px 0;

            * {
              font-family: ${variables.fonts.body};
            }
            &[open] {
              padding-bottom: 10px;
            }
            &[open] summary:after {
              transform: rotate(-45deg) translate(0%, 0%);
            }
          `}
        >
          <summary
            onClick={(event) => {
              onToggle(event);
            }}
            css={css`
              text-align: center;
              margin: -5px -5px 0;
              padding: 10px;
              cursor: pointer;
              list-style: none;
              font-weight: bold;

              :after {
                content: "";
                display: inline-block;
                float: right;
                width: 7px;
                height: 7px;
                border-bottom: 1px solid currentColor;
                border-left: 1px solid currentColor;
                border-bottom-left-radius: 2px;
                transform: rotate(45deg) translate(50%, 0%);
                transform-origin: center center;
                transition: transform ease-in-out 100ms;
              }
              :focus {
                outline: none;
              }
              &::-webkit-details-marker {
                display: none;
              }
            `}
          >
            {toSentenceCase(
              modalWebsitePages.find((page) => currentPath === page.path)
                ?.title || ""
            )}
          </summary>
          <ul
            style={{ padding: "0.2rem", margin: "0", counterReset: "labels" }}
          >
            {modalWebsitePages.map((page, idx) => {
              return (
                <li
                  key={`label-${idx}`}
                  onClick={() => {
                    setCurrentPath(page.path || "/");
                  }}
                  style={{
                    display: page.path === currentPath ? "none" : "block",
                    margin: 0,
                    listStyleType: "none",
                  }}
                >
                  <label
                    htmlFor={`radio-${idx}`}
                    css={css`
                      width: 100%;
                      display: block;
                      cursor: pointer;
                      padding: 5px;

                      :hover {
                        background-color: ${variables.colors.hover};
                        padding: 6px;
                      }
                    `}
                  >
                    {toSentenceCase(page.title)}
                  </label>
                </li>
              );
            })}
          </ul>
        </details>
      </header>

      <div
        style={{
          fontFamily: variables.fonts.body,
          overflowY: "auto",
          overflowX: "hidden",
          paddingBottom: "10px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {toSentenceCase(
            modalWebsitePages.find((page) => currentPath === page.path)
              ?.title || ""
          )}
        </h2>
        {children}
      </div>
    </div>
  );
};

export default MobileTemplate;
