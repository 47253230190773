import React from "react";

// mui
import { Typography } from "@material-ui/core";

const Heading: React.FC = () => {
  return (
    <Typography
      variant="h5"
      sx={{ textAlign: "center", fontWeight: 600, mb: 5 }}
    >
      Suggest A Feature
    </Typography>
  );
};

export default Heading;
