import { LocalStorageUtils } from ".";
import { drawerPages } from "../components/Drawer/Drawer";
import { modalWebsitePages } from "../screens/ModalWebsite/ModalWebsite";

const MODAL_OPEN_OR_CLOSED_KEY = "modal_open_or_closed";

const getIsOpenModalState = (pathname: string) => {
  const localStorageValue = LocalStorageUtils.get(MODAL_OPEN_OR_CLOSED_KEY);
  let shouldModalBeOpen = false;

  // todo: smelly code, can we do the better? start w/ hardcoding the homepage pathname?
  if (pathname !== "/" && drawerPages.find((page) => page.path === pathname)) {
    return false;
  }

  // note: arrow functions are weird; you can't return from the arrow function and have that be the return value of getIsOpenModalState. Hence, the need for shouldModalBeOpen
  modalWebsitePages.forEach((page) => {
    if (pathname === "/") {
      shouldModalBeOpen =
        localStorageValue === "" ? true : Boolean(+localStorageValue);
    } else if (page.path === pathname) {
      shouldModalBeOpen = true;
    }
  });

  return shouldModalBeOpen;
};

const setIsOpenModalState = (state: boolean) =>
  LocalStorageUtils.set(MODAL_OPEN_OR_CLOSED_KEY, (+state).toString());

const useWebsiteModalVisibleFlag = (
  pathname: string
): [boolean, (state: boolean) => void] => {
  const shouldModalBeOpen = getIsOpenModalState(pathname);

  // like useState
  const stateAndStateSetter: [boolean, (state: boolean) => void] = [
    shouldModalBeOpen,
    setIsOpenModalState,
  ];

  return stateAndStateSetter;
};

export default useWebsiteModalVisibleFlag;
