import React, { CSSProperties } from "react";
import variables from "../shared/variables";

interface ButtonProps {
  onClick?: React.MouseEventHandler;
}

const buttonStyle: CSSProperties = {
  backgroundColor: "black",
  fontFamily: variables.fonts.body,
  color: "white",
  padding: "0.4rem 1rem",
  fontWeight: 700,
  borderRadius: "1rem",
  border: "none",
};

const Button: React.FC<ButtonProps> = ({ onClick, children }) => {
  return (
    <button style={buttonStyle} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
