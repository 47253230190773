import { createTheme } from "@mui/material/styles";
import variables from "../shared/variables";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    google: {
      main: "#d35151",
      contrastText: "#fff",
    },
    facebook: {
      main: "#4d86db",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Helvetica, sans-serif",
  },
  zIndex: {
    underModal: variables.numbers.underModalZIndex,
  },
});

export default theme;
