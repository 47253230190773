const Help = () => {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>The Help Page</h1> */}
      <p>
        Since we just launched, we do not know the sort of help you would like!
        So if you need help using the app or have a question for us, use the
        suggest a feature link in the drawer menu (close this window and click
        the button with the three stripes.)
      </p>
    </div>
  );
};

export default Help;
