import React, { useState } from "react";
import { Message } from "../../api/points";
import variables from "../../shared/variables";
import { format } from "timeago.js";
import images from "../../shared/images";
import { IoHeartOutline, IoHeart, IoChatbubblesOutline } from "react-icons/io5";
import { getUserId } from "../../shared/getUserId";
import { trackEvent } from "../../analytics/SegmentAnalytics";
import ReactPlayer from "react-player/lazy";
import { LinkItUrl, LinkItTwitter, LinkItEmail } from "react-linkify-it";
import { useNavigate } from "react-router-dom";

// firebase
import { likePost } from "../../firebase/points";

// redux
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/userSlice";

// mui
import {
  Stack,
  Avatar,
  IconButton,
  Typography,
  Box,
  ImageList,
} from "@mui/material";

// components
import CustomAlert from "../CustomAlert/CustomAlert";
import MessageImage from "./MessageImage";
import CommentModal from "../CommentModal/CommentModal";

interface MessageListItemProps {
  message: Message;
  index?: number;
  showLocation: boolean;
  onClick?: React.MouseEventHandler;
}

const filterGray = "#777";

const MessageListItem: React.FC<MessageListItemProps> = ({ message }) => {
  const blankAvatarImgPath =
    images.find((image) => image.id === "light_mode_avatar")?.path || "";

  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);

  const user = useAppSelector(selectUser);
  const uid = getUserId();
  const postImages = message.images as string[];

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
    navigate(`/post/${message.pointId}`);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleLike = async () => {
    if (!user) {
      setErrorMessage("You need to be signed in to like posts");
      return;
    }

    try {
      // TODO: don't get the description from likePost function -- separation of concerns
      const description = await likePost(
        user.id,
        user.username,
        message.pointId as string,
        message
      );

      // track like/unlike events
      trackEvent(description, {
        username: user?.username,
        email: user?.email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseErrorAlert = () => {
    setErrorMessage("");
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: "relative",
          borderBottom: "1px solid #ddd",
          minHeight: "5rem",
          paddingTop: "0.6rem",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            fontFamily: "monospace",
            fontSize: "0.7rem",
            marginRight: "0.3rem",
            marginTop: "0.2rem",
            color: "darkolivegreen",
          }}
        >
          {message.location}
        </Box>

        <Stack
          sx={{
            width: "30%",
            padding: "1.2rem 0.7rem",
          }}
          alignItems="center"
          spacing={1}
        >
          <Avatar
            alt="profile image"
            src={blankAvatarImgPath}
            sx={{ width: "2rem", height: "2rem" }}
          />

          <Typography
            sx={{
              fontFamily: variables.fonts.header,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: "center",
              fontSize: {
                xs: "12px",
                sm: "15px",
              },
            }}
          >
            @{message.username}
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: { xs: "70%", sm: "600px" },
            padding: "1.2rem 0.7rem",
          }}
          spacing={1}
        >
          {/* post message */}
          <Typography
            sx={{
              fontSize: {
                xs: "12px",
                sm: "15px",
              },
            }}
          >
            <LinkItUrl>
              <LinkItTwitter>
                <LinkItEmail>{message.message}</LinkItEmail>
              </LinkItTwitter>
            </LinkItUrl>
          </Typography>

          {/* post image(s) */}
          {postImages.length > 0 && (
            <ImageList
              variant="quilted"
              sx={{
                width: "100%",
                height: 250,
              }}
              cols={3}
              rowHeight={postImages.length > 1 ? 125 : 250}
            >
              {postImages.map((image, idx) => {
                return (
                  <MessageImage
                    key={idx}
                    images={postImages}
                    image={image}
                    index={idx}
                  />
                );
              })}
            </ImageList>
          )}

          {message.video && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
                height: 250,
                border: "1px solid black",
              }}
            >
              <ReactPlayer
                url={message.video}
                controls
                width="100%"
                height="100%"
                style={{
                  backgroundColor: "black",
                }}
              />
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption" sx={{ color: filterGray }}>
              {format(message.time)}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row" spacing={0.2} alignItems="center">
                <Typography variant="caption">
                  {message.comments?.length}
                </Typography>
                <IconButton
                  sx={{ padding: 0 }}
                  disableRipple
                  onClick={handleClickOpen}
                >
                  <IoChatbubblesOutline size="20px" />
                </IconButton>
              </Stack>

              <Stack direction="row" spacing={0.2} alignItems="center">
                <Typography variant="caption">
                  {message.likesArray?.length}
                </Typography>
                <IconButton
                  sx={{ padding: 0 }}
                  disableRipple
                  onClick={handleLike}
                >
                  {message?.likesArray?.includes(uid) ? (
                    <IoHeart size="20px" color="black" />
                  ) : (
                    <IoHeartOutline size="20px" />
                  )}
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <CustomAlert
        onClose={handleCloseErrorAlert}
        severity="error"
        message={errorMessage}
      />

      <CommentModal
        handleClose={handleClose}
        open={open}
        message={message}
        handleLike={handleLike}
      />
    </>
  );
};

export default MessageListItem;
