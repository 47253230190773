import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// TODO: Delete allPoints allQuestions?
import { Point, allPoints, allQuestions } from "../../api/points";

/* Todo: Have an App state that we fork from this PointsState */
interface PointsState {
  posts: Point[];
  queries: Point[];
  currentView: "Post" | "Query" | "Menu" | "Search";
  isThinking: boolean;
  bitlyLinks: { [key: string]: string } | Record<string, never>;
  centerMode: "fixed" | "floating";
  currentLocation: { lat: number; lng: number };
}

const initialState: PointsState = {
  posts: [],
  queries: [],
  currentView: "Menu",
  isThinking: false,
  centerMode: "fixed",
  bitlyLinks: {},
  currentLocation: { lat: 0, lng: 0 },
};

const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {
    setPosts: (state, { payload }) => {
      state.posts = payload;
    },
    setQueries: (state, { payload }) => {
      state.queries = payload;
    },
    setCurrentView: (state, { payload }) => {
      state.currentView = payload;
    },
    setIsThinking: (state, { payload }) => {
      state.isThinking = payload;
    },
    setCenterMode: (state, { payload }) => {
      state.centerMode = payload;
    },
    // todo: if these (addPost, addQuery, setIsThinking, setCenterMode) aren't needed, they should be deleted
    addPost: (state, { payload }) => {
      // console.log(payload);
    },
    addQuery: (state, { payload }) => {
      // console.log(payload);
    },
    setCurrentLocation: (state, { payload }) => {
      state.currentLocation = payload;
    },
    setBitlyLinks: (state, { payload }) => {
      state.bitlyLinks = payload;
    },
  },
});

export const {
  setPosts,
  setQueries,
  setCurrentView,
  setIsThinking,
  setCenterMode,
  addPost,
  addQuery,
  setCurrentLocation,
  setBitlyLinks,
} = pointsSlice.actions;

// state selectors
export const selectPosts = (state: RootState) => state.points.posts;
export const selectQueries = (state: RootState) => state.points.queries;
export const selectIsThinking = (state: RootState) => state.points.isThinking;
export const selectCurrentView = (state: RootState) => state.points.currentView;
export const selectCenterMode = (state: RootState) => state.points.centerMode;
export const selectCurrentLocation = (state: RootState) =>
  state.points.currentLocation;
export const selectBitlyLinks = (state: RootState) => state.points.bitlyLinks;
export default pointsSlice.reducer;
