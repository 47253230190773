import { User } from "../../redux/user/userSlice";
import DashboardTemplate from "./DashboardTemplate";
import { allActualUsers } from "../../firebase/user";
import { useState, useEffect } from "react";
import { getPosts } from "./ambassadorData";
import { studentAmbassadors } from "./ambassadorList";

/*

  + total # of non-paid
  + total posts by non-paid

  * tables:
    * number of posts by non-paid per day
    * # of actual users who showed per day
    * average time spend on app per day by non-paid
      * defined as streaks of half-minute activity

*/

const DashboardMenu = () => {
  return (
    <div>
      <h2>Other Panels</h2>
      <ul>
        <li>
          <a href="/dashboard/posts-per-day">Posts per Day</a>
        </li>
      </ul>
    </div>
  );
};

const UserPanel = () => {
  const [actualUsers, setActualUsers] = useState<User[]>();
  const [numActualPosts, setNumActualPosts] = useState<number>();

  useEffect(() => {
    (async () => {
      setActualUsers(await allActualUsers());
    })();
  }, []);

  useEffect(() => {
    if (actualUsers) {
      (async () => {
        const posts = await getPosts(actualUsers.map((user) => user.id));
        setNumActualPosts(posts.length);
      })();
    }
  }, [actualUsers]);

  return (
    <div>
      <h2>Actual User Stats</h2>
      <ul>
        <li>
          <span>
            <em>Number of actual users</em>:{" "}
          </span>
          <span>{actualUsers?.length}</span>
        </li>
        <li>
          <span>
            <em>Number of posts by actual users</em>:{" "}
          </span>
          <span>{numActualPosts}</span>
        </li>
      </ul>
    </div>
  );
};

export default function IndexAmbassadors({
  ambassadorUsers,
}: {
  ambassadorUsers: { email: string; id: string }[];
}) {
  return (
    <DashboardTemplate>
      <div>
        <h1 style={{ fontFamily: "Helvetica", textAlign: "center" }}>
          Agama Ambassador List
        </h1>
        <ul>
          {ambassadorUsers.map((user) => (
            <li key={user.email}>
              <a href={`/dashboard/${user.id}`}>{user.email}</a>
            </li>
          ))}
        </ul>
        <br />
        <UserPanel />
        <DashboardMenu />
      </div>
    </DashboardTemplate>
  );
}
